import { getCustomRoutes } from '../../common/store/custom-routes/custom-routes-selectors';
import { getBaseUrl } from '../../common/store/location/location-selectors';
import {
  buildCustomUrl,
  getCustomRoute,
} from '@wix/communities-forum-client-commons/dist/src/services/custom-routing-service';

export function buildCustomizedUrl(state, path, isFullUrl = true) {
  const { customRouteKey, params } = getCustomRoute(path);
  const baseUrl = getBaseUrl(state);
  return buildCustomUrl(getCustomRoutes(state), customRouteKey, params, !isFullUrl && baseUrl);
}
