import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import EmptyStates from '../empty-states';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './restriction-private-member.scss';
import Button from '../button';

const RestrictionPrivateMember = ({ showJoinCommunityPopup, t }) => (
  <EmptyStates
    className={classNames(styles.emptyStates, 'restriction')}
    title={t('restriction-private-member.title')}
    content={t('restriction-private-member.content')}
    type="private_member"
  >
    <Button className={classNames('login-button')} onClick={() => showJoinCommunityPopup()}>
      {t('restriction-private-member.button')}
    </Button>
  </EmptyStates>
);

RestrictionPrivateMember.propTypes = {
  showJoinCommunityPopup: PropTypes.func,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  showJoinCommunityPopup: actions.showJoinCommunityPopup,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(RestrictionPrivateMember);
