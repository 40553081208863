import React from 'react';

export const SortIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill-rule="evenodd"
      d="M14.5 16c.276 0 .5.224.5.5s-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5zm2-5c.276 0 .5.224.5.5s-.224.5-.5.5h-9c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h9zm3-5c.276 0 .5.224.5.5s-.224.5-.5.5h-15c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h15z"
    />
  </svg>
);
