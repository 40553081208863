export const REDUCERS = {
  APP_LOADED: 'appLoaded',
  FAST_FORUM_SUBMIT: 'fastFormSubmit',
  DEBUG_STATE: 'debugState',
  PROMISIFIED_ACTIONS_RESULTS: 'promisifiedActionsResults',
  UPLOAD_REGISTRY: 'uploadedRegistry',
  EXPERIMENTS: 'experiments',
  TRANSLATIONS: 'translations',
  BASIC_PARAMS: 'basicParams',
  MODAL: 'modal', //
  ROUTER: 'router', //
  MESSAGE: 'MESSAGE', //
  COMMENTS: 'comments',
  COMMUNITIES_CONTEXT: 'communitiesContext',
  INSTANCE_VALUES: 'instanceValues',
  IS_LOADED: 'isLoaded',
  IS_LOADING: 'isLoading',
  MEMBERS_GROUPS: 'membersGroups',
  LOCATION: 'location',
  MA_NAVIGATION: 'maNavigation',
  PAGINATION: 'pagination',
  POSTS: 'posts',
  SEARCH: 'search',
  CATEGORIES: 'categories',
  TYPING: 'typing',
  RCE_TRANSLATIONS: 'rceTranslations',
  CATEGORIES_MANAGER: 'categoriesManager',
  CURRENT_USER: 'currentUser',
  SORTING: 'sorting',
  DEEP_LINK_ROUTING: 'deepLinkRouting',
  FOOTER_POSTS: 'footerPosts',
  RELATED_POSTS: 'relatedPosts',
  BADGES: 'badges',
  PAID_PLANS: 'paidPlans',
  STYLE_PARAMS: 'styleParams',
  SAVED_ACTION: 'savedAction',
  MANAGE_CATEGORY_MEMBERS: 'manageCategoryMembers',
  SITE_MEMBERS: 'siteMembers',
  FILTERING: 'filtering',
  OFFLINE_MIGRATION: 'offlineMigration',
  RELOAD_PAGE: 'reloadPage',
  GLOBAL_LOADER: 'globalLoader',
  WHO_LIKED: 'whoLiked',
  SITE_ID: 'siteId',
  SIMILAR_POSTS: 'similarPosts',
  EXTERNAL_LINKS: 'externalLinks',
  LOCAL_STORAGE: 'localStorage',
  COUNTERS: 'counters',
  FORUM_DATA: 'forumData',
  COMMENT_EDIT: 'commentEdit',
};

export const REDUCERS_ALL = [
  REDUCERS.APP_LOADED,
  REDUCERS.FAST_FORUM_SUBMIT,
  REDUCERS.DEBUG_STATE,
  REDUCERS.PROMISIFIED_ACTIONS_RESULTS,
  REDUCERS.UPLOAD_REGISTRY,
  REDUCERS.EXPERIMENTS,
  REDUCERS.TRANSLATIONS,
  REDUCERS.BASIC_PARAMS,
  REDUCERS.MODAL,
  REDUCERS.ROUTER,
  REDUCERS.MESSAGE,
  REDUCERS.COMMENTS,
  REDUCERS.COMMUNITIES_CONTEXT,
  REDUCERS.INSTANCE_VALUES,
  REDUCERS.IS_LOADED,
  REDUCERS.IS_LOADING,
  REDUCERS.MEMBERS_GROUPS,
  REDUCERS.LOCATION,
  REDUCERS.MA_NAVIGATION,
  REDUCERS.PAGINATION,
  REDUCERS.POSTS,
  REDUCERS.SEARCH,
  REDUCERS.CATEGORIES,
  REDUCERS.TYPING,
  REDUCERS.RCE_TRANSLATIONS,
  REDUCERS.CATEGORIES_MANAGER,
  REDUCERS.CURRENT_USER,
  REDUCERS.SORTING,
  REDUCERS.DEEP_LINK_ROUTING,
  REDUCERS.FOOTER_POSTS,
  REDUCERS.RELATED_POSTS,
  REDUCERS.BADGES,
  REDUCERS.PAID_PLANS,
  REDUCERS.STYLE_PARAMS,
  REDUCERS.SAVED_ACTION,
  REDUCERS.MANAGE_CATEGORY_MEMBERS,
  REDUCERS.SITE_MEMBERS,
  REDUCERS.FILTERING,
  REDUCERS.OFFLINE_MIGRATION,
  REDUCERS.RELOAD_PAGE,
  REDUCERS.GLOBAL_LOADER,
  REDUCERS.WHO_LIKED,
  REDUCERS.SITE_ID,
  REDUCERS.SIMILAR_POSTS,
  REDUCERS.EXTERNAL_LINKS,
  REDUCERS.LOCAL_STORAGE,
  REDUCERS.COUNTERS,
  REDUCERS.FORUM_DATA,
  REDUCERS.COMMENT_EDIT,
];
