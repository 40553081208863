import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import CategoryPage from '../category-page';
import CategoriesPage from '../categories-page';
import { getIsMainPageEnabled } from '../../selectors/app-settings-selectors';

class HomePage extends Component {
  render() {
    const { isMainPageEnabled } = this.props;
    return isMainPageEnabled ? (
      <CategoriesPage {...this.props} />
    ) : (
      <CategoryPage {...this.props} />
    );
  }
}

HomePage.propTypes = {
  isMainPageEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isMainPageEnabled: getIsMainPageEnabled(state, host.style),
});

export default connect(mapRuntimeToProps)(HomePage);
