export function getQuestionPlaceholder(isNewQuestionPlaceholderEnabled, t) {
  const bullets = [
    '',
    'text-editor.placeholder-give-details-line.first',
    'text-editor.placeholder-give-details-line.second',
    'text-editor.placeholder-give-details-line.third',
  ]
    .map(k => t(k))
    .join('\n    •    ');

  return isNewQuestionPlaceholderEnabled
    ? t('text-editor.placeholder-give-details-intro') + bullets
    : t('text-editor.placeholder-give-details');
}
