import PropTypes from 'prop-types';
import React from 'react';
import { getSecondaryColorNumbers } from '../../services/get-colors';
import styles from './highlighter.scss';
import { loadable } from '@wix/communities-forum-client-commons/dist/src/services/loadable';

const ReactHighlightWords = loadable(
  () => import(/* webpackChunkName: "react-highlight-words-frameless" */ 'react-highlight-words'),
  {
    loading: props => <span>{props.text}</span>,
  },
);

const STYLE = {
  backgroundColor: `rgba(${getSecondaryColorNumbers()}, 0.3)`,
};

const Highlighter = ({ text, query }) => (
  <ReactHighlightWords
    highlightClassName={styles.mark}
    highlightStyle={STYLE}
    textToHighlight={text}
    searchWords={query.split(' ')}
    autoEscape
  />
);

Highlighter.propTypes = {
  text: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
};

export default Highlighter;
