import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { HorizontalSeparator } from '../separator/index';
import PostExcerptFooter from '../post-excerpt-footer';
import withFontClassName from '../../hoc/with-font-class-name';
import withSettingsColor from '../../hoc/with-settings-color';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import styles from './post-excerpt.scss';
import Link from '../link/internal-link';

export const PostExcerpt = ({
  post,
  categorySlug,
  onLikeClick,
  titleFontClassName,
  contentFontClassName,
  postMetadataFontSize,
  borderColor,
}) => {
  const postLink = `/${categorySlug}/${post.slug}`;
  return (
    <div
      className={classNames(styles.postExcerpt, 'forum-card-background-color')}
      style={{ borderColor }}
    >
      <div className={titleFontClassName}>
        <h2 className={styles.postTitle}>
          <Link to={postLink} className={classNames('forum-text-color', 'forum-link-hover-color')}>
            {post.title}
          </Link>
        </h2>
      </div>
      <HorizontalSeparator className={styles.separator} />
      <div
        style={{ fontSize: postMetadataFontSize }}
        className={classNames(styles.metaContainer, contentFontClassName)}
      >
        <PostExcerptFooter {...{ post, onLikeClick }} />
      </div>
    </div>
  );
};

PostExcerpt.propTypes = {
  post: PropTypes.object.isRequired,
  categorySlug: PropTypes.string.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  postMetadataFontSize: PropTypes.number,
  borderColor: PropTypes.string,
};

PostExcerpt.defaultProps = {
  postMetadataFontSize: 12,
};

export default flowRight(
  withFontClassName,
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'borderColor',
    alpha: 0.2,
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
  }),
)(PostExcerpt);
