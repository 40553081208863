import { has, get, map } from 'lodash';

const isLegacyEntityLoading = entity => entity.data.isLoading;

const isUploadingImage = entity => entity.type === 'wix-draft-plugin-image' && !entity.data.src;

const isUploadingVideo = entity =>
  entity.type === 'wix-draft-plugin-video' && get(entity.data, 'tempData', false);

const isUploadingFile = entity =>
  entity.type === 'wix-draft-plugin-file-upload' &&
  !has(entity.data, 'id') &&
  get(entity.data, 'tempData', false);

const hasActiveUploads = content =>
  map(
    content.entityMap,
    entity =>
      isLegacyEntityLoading(entity) ||
      isUploadingImage(entity) ||
      isUploadingVideo(entity) ||
      isUploadingFile(entity),
  ).some(value => value);

export default hasActiveUploads;
