import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import InputText from '../input-text';
import RichContentEditor from '../rich-content-editor-async';
import RichContentEditorFooter from '../rich-content-editor-footer';
import postFormSettings from '../../services/post-form-settings';
import { isDiscussion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import { withFastForm } from '../../../common/components/fast-form';
import { connect } from '../../../common/components/runtime-context';
import { SolvedIcon } from '../icons/solved-icon';
import getPostTheme from '../rich-content-editor/theme-post-form';
import styles from './post-form-mobile.scss';
import { EXPERIMENT_NEW_QUESTION_PLACEHOLDER } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import withExperiment from '../../hoc/with-experiment';
import { getQuestionPlaceholder } from './post-form-common';

export class PostFormMobile extends Component {
  onContentChange = value => this.props.fastForm.changeValue('content')(value);

  render() {
    const {
      postId,
      contentFontClassName,
      titleFontClassName,
      fastForm: { values, changeValue, isValid },
      postType,
      t,
      isNewQuestionPlaceholderEnabled,
    } = this.props;
    const titleValue = values.title || '';
    const onTitleChange = value => changeValue('title')(value);
    const containerClassName = classNames(
      styles.container,
      'forum-text-color',
      contentFontClassName,
    );
    const isDiscussionType = isDiscussion(postType);

    return (
      <div
        className={classNames(
          containerClassName,
          'forum-card-background-color',
          'forum-card-border-color',
        )}
      >
        <div className={styles.content}>
          <div className={styles.titleInputWrapper}>
            <InputText
              value={titleValue}
              onChange={onTitleChange}
              containerClassName={classNames(titleFontClassName, styles.titleInputContainer)}
              className={classNames(styles.titleInput, 'forum-text-color')}
              maxLength={140}
              aria-label={t('post-form.title')}
              placeholder={t(
                isDiscussionType
                  ? 'post-form.give-this-post-a-title'
                  : 'post-form.your-question-here',
              )}
              autoFocus={!postId}
              data-hook="post-form__title-input"
              inputRef={r => (this.titleInputRef = r)}
            />
          </div>
          <div className={classNames(styles.editor, 'post-form__text-editor')}>
            <RichContentEditor
              initialState={values.content || undefined}
              placeholder={t(
                isDiscussion(postType)
                  ? 'text-editor.placeholder'
                  : getQuestionPlaceholder(isNewQuestionPlaceholderEnabled, t),
              )}
              onChange={this.onContentChange}
              focus={false}
              origin="post"
              showAdvancedImageSettings={false}
              themeGetter={getPostTheme}
              setRef={ref => (this.editor = ref)}
            />
          </div>
        </div>
        <RichContentEditorFooter submitIcon={postId && SolvedIcon} isDisabled={!isValid} />
      </div>
    );
  }
}

PostFormMobile.propTypes = {
  onSubmitButtonClick: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func,
  postId: PropTypes.string,
  showMessage: PropTypes.func,
  fastForm: PropTypes.object,
  postType: PropTypes.string,
  isNewQuestionPlaceholderEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  showMessage: actions.showMessage,
});

export default flowRight(
  withFastForm(postFormSettings),
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withExperiment({ isNewQuestionPlaceholderEnabled: EXPERIMENT_NEW_QUESTION_PLACEHOLDER }),
)(PostFormMobile);
