import React from 'react';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import styles from './deep-link-loader.scss';

const DeepLinkLoader = () => (
  <div className={styles.container}>
    <Loader />
  </div>
);

export default DeepLinkLoader;
