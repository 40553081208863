import React from 'react';

export const AllStateCtx = React.createContext({
  state: undefined,
  actions: undefined,
  host: undefined,
  whenWorkerInitialized: undefined,
});

export default AllStateCtx;
