import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from '../../../common/components/runtime-context';
import { getIsMobile, isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import AvatarGroup from '../avatar-group';
import withTranslate from '../../../common/components/with-translate/with-translate';
import MoreButton from '../more-button';
import styles from './who-liked-popover.scss';
import WhoLikedList from './who-liked-list';
import { MODAL_TYPE_WHO_LIKED_LIST } from '../modals/who-liked-list-modal/who-liked-list-modal-type';
import { withPreviewClickBlocker } from '../../../common/components/click-blocker/with-preview-click-blocker';

const LikeCount = props => {
  return (
    <span
      className={classNames(styles.likeCount, 'post-main-actions__like-count', {
        '.forum-link-hover-color:hover': props.totalItemCount > 0,
        [styles.noHover]: props.totalItemCount === 0,
      })}
      data-hook={props.dataHook}
      onClick={props.onClick}
    >
      {props.totalItemCount}
    </span>
  );
};

class WhoLikedPopover extends Component {
  reportBi = () => {
    this.props.buttonClicked({ name: 'who_liked', type: this.props.type });
  };
  render() {
    const {
      isMobile,
      totalItemCount,
      initialItems,
      postId,
      commentId,
      openModal,
      isSSR,
      preventClickInPreview,
      t,
    } = this.props;

    const LikeCountCTA = !isSSR ? AvatarGroup : LikeCount;

    if (totalItemCount === 0) {
      return (
        <LikeCount
          totalItemCount={totalItemCount}
          items={initialItems}
          isMobile={isMobile}
          dataHook="who-liked-popover-btn"
        />
      );
    }
    return isMobile ? (
      <LikeCountCTA
        className={styles.moreButton}
        totalItemCount={totalItemCount}
        items={initialItems}
        dataHook="who-liked-popover-btn"
        size={'xxSmall'}
        showWithGaps={!isMobile}
        onClick={preventClickInPreview(() => {
          openModal(MODAL_TYPE_WHO_LIKED_LIST, { postId, commentId });
          this.reportBi();
        })}
        limit={2}
        isMobile
      />
    ) : (
      <MoreButton
        ariaLabel={t('who-liked.aria-label')}
        icon={
          <LikeCountCTA
            totalItemCount={totalItemCount}
            items={initialItems}
            dataHook="who-liked-popover-btn"
            isMobile={isMobile}
          />
        }
        actionsContainerClassName={styles.popOverContainer}
        parentRef={this.getParentRef}
        position="right"
        onShow={this.reportBi}
        noCloseOnContainerClick
        className={styles.moreButton}
      >
        {<WhoLikedList postId={postId} commentId={commentId} />}
      </MoreButton>
    );
  }
}

WhoLikedPopover.propTypes = {
  isMobile: PropTypes.bool,
  isSSR: PropTypes.bool,
  totalItemCount: PropTypes.number,
  initialItems: PropTypes.array,
  postId: PropTypes.array,
  commentId: PropTypes.array,
  buttonClicked: PropTypes.func,
  preventClickInPreview: PropTypes.func,
  type: PropTypes.oneOf(['post', 'comment', 'reply']),
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isMobile: getIsMobile(state),
  openModal: actions.openModal,
  buttonClicked: actions.buttonClicked,
  isSSR: isSSR(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withPreviewClickBlocker,
)(WhoLikedPopover);
