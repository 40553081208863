import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import AvatarImage from '../avatar-image';

const UserAvatarImage = ({ user = {}, ...props }) => (
  <AvatarImage image={user.image} user={user} {...omit(props, 'dispatch')} />
);

hoistNonReactStatics(UserAvatarImage, AvatarImage);

UserAvatarImage.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  type: PropTypes.string,
};

export default UserAvatarImage;
