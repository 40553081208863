import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isNumber, flowRight } from 'lodash';
import { connect } from '../../common/components/runtime-context';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as params from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import withAppSettings from './with-app-settings';
import getDisplayName from '@wix/communities-forum-client-commons/dist/src/services/get-display-name';
import getLayoutName from '../services/get-layout-name';
import { getLayoutType } from '../selectors/layout-selectors';
import { getCardSize } from '../selectors/app-settings-selectors';

const DEFAULT_WIDTH = 1;

export default function withCardBorderWidth(WrappedComponent) {
  const Wrapper = class extends Component {
    static displayName = `WithCardBorderWidth(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      layoutType: PropTypes.number,
      cardSize: PropTypes.number,
      borderWidthClassic: PropTypes.number,
      borderWidthSmall: PropTypes.number,
      borderWidthMedium: PropTypes.number,
      borderWidthLarge: PropTypes.number,
      borderWidthCompact: PropTypes.number,
    };

    render() {
      const {
        layoutType,
        cardSize,
        borderWidthClassic,
        borderWidthSmall,
        borderWidthMedium,
        borderWidthLarge,
        borderWidthCompact,
        ...otherProps
      } = this.props;
      const layoutName = getLayoutName(layoutType, cardSize);
      let borderWidth;

      switch (layoutName) {
        case 'classic':
          borderWidth = borderWidthClassic;
          break;
        case 'small':
          borderWidth = borderWidthSmall;
          break;
        case 'medium':
          borderWidth = borderWidthMedium;
          break;
        case 'large':
          borderWidth = borderWidthLarge;
          break;
        case 'compact':
          borderWidth = borderWidthCompact;
          break;
        default:
          borderWidth = DEFAULT_WIDTH;
      }

      return (
        <WrappedComponent
          borderWidth={isNumber(borderWidth) ? borderWidth : DEFAULT_WIDTH}
          {...otherProps}
        />
      );
    }
  };

  hoistNonReactStatics(Wrapper, WrappedComponent);

  const mapRuntimeToProps = (state, ownProps, actions, host) => ({
    layoutType: getLayoutType(state, host.style),
    cardSize: getCardSize(state, host.style),
  });

  return flowRight(
    connect(mapRuntimeToProps),
    withAppSettings({
      borderWidthClassic: params.BORDER_WIDTH_CLASSIC_PATH,
      borderWidthSmall: params.BORDER_WIDTH_SMALL_PATH,
      borderWidthMedium: params.BORDER_WIDTH_MEDIUM_PATH,
      borderWidthLarge: params.BORDER_WIDTH_LARGE_PATH,
      borderWidthCompact: params.BORDER_WIDTH_COMPACT_PATH,
    }),
  )(Wrapper);
}
