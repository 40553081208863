import { get, noop } from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './invalid-app-state-page.scss';
import { INVALID_APP_STATE_PAGE } from '../../constants/page-names';
import { isExperimentEnabled } from '../../selectors/experiments-selectors';
import { EXPERIMENT_NEW_BROKEN_STATE } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

const BACKUP_TRANSLATIONS = {
  'broken-state.title': 'Something Isn’t Working…',
  'broken-state.main-message': 'Refresh the page to try again.',
  'broken-state.btn-text': 'Refresh Page',
  'broken-state.suggestions-title':
    'It looks like there was a technical problem. Here’s what you can do:',
  'broken-state.suggestion-1': 'Refresh the page.',
  'broken-state.suggestion-2': 'Check your internet connection.',
  'broken-state.suggestion-3': 'Open the site in a different browser.',
};

export class InvalidAppStatePage extends React.Component {
  componentDidMount() {
    const pageOpened = get(this.props, 'actions.pageOpened', noop);
    pageOpened({ page: INVALID_APP_STATE_PAGE });
  }
  render() {
    const t = key => get(this.props, `state.translations['${key}']`, BACKUP_TRANSLATIONS[key]);
    const error = get(this.props, 'workerError.error');
    const errorCode = get(this.props, 'workerError.errorId');
    if (error) {
      console.info(error);
    }
    const state = get(this.props, 'state');
    const isNewBrokenState = state && isExperimentEnabled(state, EXPERIMENT_NEW_BROKEN_STATE);
    return (
      <div className={styles.container}>
        <div tabIndex="0" className={styles.innerContainer}>
          <h2
            className={classNames(styles.title, 'invalid-app-state-page-title')}
            style={{ marginBottom: '12px' }}
          >
            {t('broken-state.title')}
          </h2>
          <span
            className={classNames(styles.body, 'invalid-app-state-page-body')}
            style={{ marginBottom: '24px' }}
          >
            {t(isNewBrokenState ? 'broken-state.suggestions-title' : 'broken-state.main-message')}
            {isNewBrokenState && (
              <ul className={styles.list}>
                <li>{t('broken-state.suggestion-1')}</li>
                <li>{t('broken-state.suggestion-2')}</li>
                <li>{t('broken-state.suggestion-3')}</li>
              </ul>
            )}
          </span>
          <button
            onClick={() => window.location.reload()}
            className={classNames(styles.button, 'color-5-text')}
            style={{ cursor: 'pointer' }}
          >
            {t('broken-state.btn-text')}
          </button>

          {errorCode && (
            <div style={{ marginTop: '12px', fontSize: '10px', opacity: '0.8' }}>
              ErrorId: {errorCode}
            </div>
          )}
        </div>
      </div>
    );
  }
}

InvalidAppStatePage.propTypes = {
  t: PropTypes.func,
};

export default InvalidAppStatePage;
