import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { SortIcon } from '../icons/sort-icon';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './filter-and-sort-button.scss';
import { withPreviewClickBlocker } from '../../../common/components/click-blocker/with-preview-click-blocker';

const FilterAndSortButton = ({
  onClick,
  contentFontClassName,
  preventClickInPreview,
  isFilter = false,
  t,
}) => {
  const labelKey = isFilter ? 'filter-and-sort-button.filter' : 'filter-and-sort-button.sort';
  return (
    <button
      className={classNames(styles.container, 'forum-text-color', contentFontClassName)}
      onClick={preventClickInPreview(onClick)}
      data-hook="filter-and-sort-button"
    >
      <span className={classNames(styles.text, 'button-color')}>{t(labelKey)}</span>
      <SortIcon className="button-fill" />
    </button>
  );
};

FilterAndSortButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  preventClickInPreview: PropTypes.func.isRequired,
  isFilter: PropTypes.bool,
};

export default flowRight(
  withTranslate,
  withFontClassName,
  withPreviewClickBlocker,
)(FilterAndSortButton);
