import { AvatarGroup as WUTAvatarGroup } from 'wix-ui-tpa/AvatarGroup';
import React from 'react';
import classNames from 'classnames';
import { st, classes } from './avatar-group.st.css';
import styles from './avatar-group.scss';

const AvatarGroup = ({
  items = [],
  totalItemCount,
  onClick,
  limit = 3,
  isMobile,
  dataHook,
  className,
  size = 'xSmall',
  showWithGaps,
}) => (
  <div className={classNames(styles.container, className)} onClick={onClick} data-hook={dataHook}>
    <WUTAvatarGroup
      className={st(classes.root, { withGaps: showWithGaps })}
      size={size}
      items={items.slice(0, limit).map(item => ({ name: item.name, src: item.image }))}
      maxAmount={limit}
    >
      {totalItemCount - limit > 0 && (
        <WUTAvatarGroup.TextButton
          style={{
            ...(isMobile && { fontSize: '12px' }),
            opacity: '0.8',
            marginLeft: showWithGaps ? '-10px' : '-2px',
            whiteSpace: 'nowrap',
          }}
        >
          {totalItemCount}
        </WUTAvatarGroup.TextButton>
      )}
      {totalItemCount}
    </WUTAvatarGroup>
  </div>
);

export default AvatarGroup;
