import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import CreatePostButton from '../create-post-button';
import EmptyStates from '../empty-states';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import styles from './no-posts.scss';
import { getUniquePostTypesInAllCategories } from '../../../common/selectors/categories-selectors';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../modals/categories-manager/categories-manager-modal-type';
import { MANAGE_MEMBERS } from '../../constants/categories-manager-section-types';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';

class NoPosts extends React.Component {
  componentDidMount() {
    const { category, fetchMembersGroupsList } = this.props;
    if (isPrivate(category)) {
      fetchMembersGroupsList();
    }
  }

  openMembersManager = () => {
    const { openModal, category } = this.props;
    openModal(MODAL_TYPE_CATEGORIES_MANAGER, {
      categoryId: category._id,
      openPanels: [MANAGE_MEMBERS],
    });
  };

  getTextProps = () => {
    const {
      category: { postTypes = {} },
      t,
    } = this.props;
    if (postTypes.length > 1) {
      return {
        title: t('mixed-category-page.create-new-post.title'),
        content: t('mixed-category-page.create-new-post.body'),
      };
    } else if (postTypes[0] === QUESTION) {
      return {
        title: t('questions-category-page.no-posts-yet.title'),
        content: t('questions-category-page.no-posts-yet.body'),
      };
    } else {
      return {
        title: t('category-page.no-posts-yet'),
        content: t('category-page.create-new-post'),
      };
    }
  };

  render() {
    const {
      t,
      showCreatePostAction,
      category,
      type,
      uniquePostTypesInAllCategories,
      ...rest
    } = this.props;

    const postTypes =
      uniquePostTypesInAllCategories.length === 1
        ? uniquePostTypesInAllCategories
        : category.postTypes;

    return (
      <EmptyStates {...this.getTextProps()} type={type} {...rest}>
        <div className={styles.wrapper}>
          {showCreatePostAction && (
            <CreatePostButton
              className={styles.primaryButton}
              categorySlug={category.slug}
              postTypes={postTypes}
              isSmall={false}
              isDropdown={false}
            />
          )}
        </div>
      </EmptyStates>
    );
  }
}

NoPosts.propTypes = {
  category: PropTypes.object,
  showCreatePostAction: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func,
  type: PropTypes.string,
};

const mapRuntimeToProps = (state, { category, can }, actions) => {
  const showCreatePostAction =
    category &&
    ((category.isWriteProtected && can('create-post', 'category', category)) ||
      (!category.isWriteProtected && can('read', 'category', category)));

  return {
    fetchMembersGroupsList: actions.fetchMembersGroupsList,
    showCreatePostAction,
    openModal: actions.openModal,
    uniquePostTypesInAllCategories: getUniquePostTypesInAllCategories(state),
  };
};

export default flowRight(withPermissions, connect(mapRuntimeToProps), withTranslate)(NoPosts);
