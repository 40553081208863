import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './action-button.scss';
import withFontClassName from '../../hoc/with-font-class-name';

const SIZE_SMALL = 'sizeSmall';

class ActionButton extends Component {
  render() {
    const {
      className,
      onClick,
      onMouseDown,
      children,
      dataHook,
      isButton,
      size,
      contentFontClassName,
    } = this.props;
    const icon = isArray(children) ? children[0] : null;
    const text = isArray(children) ? children[1] : children;
    const rest = isArray(children) ? children.slice(2) : null;

    return (
      <button
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={classNames(
          contentFontClassName,
          styles.actionItem,
          isButton ? '' : styles.listItem,
          className,
          'action-button',
          'forum-text-color',
          'forum-icon-fill',
          'button-hover-color',
        )}
        role={!isButton && 'menuitem'}
        data-hook="action-button"
      >
        <div className={classNames(styles.actionWrapper, styles[size])} data-hook={dataHook}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <div className={classNames(styles.actionText, 'action-button__text')}>{text}</div>
          {rest}
        </div>
      </button>
    );
  }
}

ActionButton.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  children: PropTypes.node.isRequired,
  dataHook: PropTypes.string,
  isButton: PropTypes.bool,
  size: PropTypes.string,
};

ActionButton.defaultProps = {
  onMouseDown: () => {},
};

ActionButton.SIZE_SMALL = SIZE_SMALL;

export default withFontClassName(ActionButton);
