import { flowRight, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { connect } from '../../../common/components/runtime-context';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { getIsMultiplePostTypeCategory } from '../../services/categories-service';
import { getCategory } from '../../../common/selectors/categories-selectors';
import {
  getIsListHashtagsEnabled,
  getIsViewsCountEnabled,
} from '../../selectors/app-settings-selectors';
import PostActions from '../../containers/post-actions';
import MemberCardAsync from '../../containers/member-card-async';
import MoreButton from '../more-button';
import PostTitle from '../post-title';
import Hashtags from '../hashtags';
import RoleBadge from '../role-badge';
import QuickUserInfo from '../quick-user-info/quick-user-info';
import Link from '../link/internal-link';
import ProfileLink from '../link/profile-link';
import { BulletSeparator as Bullet } from '../separator';
import { PinIcon } from '../icons/pin-icon';
import { LockIcon } from '../icons/lock-icon';
import styles from './post-list-item-classic.scss';
import withExperiment from '../../hoc/with-experiment';
import PostListItemRecentActivity from '../post-list-item-recent-activity';
import NewContentIndicator from '../new-content-indicator';
import { isPostSeen } from '../../services/post-utils';
import CounterNumber from '../counter-number';

const BulletSeparator = () => <Bullet className={'forum-text-color'} />;

export class PostListItemClassic extends Component {
  fetchPostDataView = () => {
    const { fetchPostPageData, post } = this.props;
    fetchPostPageData(post.slug, 1);
  };

  renderFooter = owner => {
    const { category, post, showCategoryLink, isListHashtagsEnabled, t } = this.props;
    const hashtags = get(post, 'hashtags');
    const showHashtags = isListHashtagsEnabled && !isEmpty(hashtags);
    const renderPostType = () => {
      const typeName =
        post.postType === QUESTION
          ? 'post-list-item.question-post-type'
          : 'post-list-item.discussion-post-type';
      return (
        <div className={classNames(styles.postType, 'forum-text-color')}>
          <BulletSeparator />
          {t(typeName)}
        </div>
      );
    };

    return (
      <div className={classNames(styles.footer, 'forum-links-hashtags-color')}>
        <MemberCardAsync
          viewedMemberId={owner.siteMemberId}
          viewedMemberSlug={owner.slug}
          className={styles.avatarLink}
        >
          <ProfileLink
            className={classNames(styles.name, 'forum-text-color', 'forum-link-hover-color', {
              [styles.obscure]: showCategoryLink,
            })}
            user={owner}
          >
            <span>{owner.name}</span>
            <RoleBadge user={owner} />
            <div className={classNames(styles.quickInfo, 'forum-text-color')}>
              <QuickUserInfo
                className={styles.badge}
                user={owner}
                date={post.createdDate}
                showDate={false}
                showBadges
                showFirstSeparator
                noMargin
              />
            </div>
          </ProfileLink>
        </MemberCardAsync>
        {getIsMultiplePostTypeCategory(category) && renderPostType()}
        {showCategoryLink && <BulletSeparator />}
        {showCategoryLink && (
          <Link
            className={classNames(
              'forum-text-color',
              'forum-link-hover-color',
              styles.categoryLabel,
            )}
            to={`/${category.slug}`}
          >
            <span>{category.label}</span>
          </Link>
        )}
        {showHashtags && <span>&nbsp;&nbsp;</span>}
        {showHashtags && <Hashtags hashtags={hashtags} className={styles.hashtags} />}
      </div>
    );
  };

  render() {
    const {
      category,
      post,
      query,
      titleFontClassName,
      contentFontClassName,
      isViewsCountEnabled,
      showViewCount,
      isRecentActivityCatsEnabled,
      t,
    } = this.props;
    const className = classNames(
      styles.container,
      'forum-text-color',
      'post-list-item',
      contentFontClassName,
    );
    const owner = post.owner || {};
    const moreButtonId = `more-button-${post._id}`;

    return (
      <tr id={post._id} className={className} data-hook="post-list-item">
        <td>
          <div
            className={classNames(styles.titleWithIcons, {
              [styles.isSeen]: isRecentActivityCatsEnabled && isPostSeen(post),
            })}
          >
            <Link
              onMouseDown={this.fetchPostDataView}
              className={classNames(titleFontClassName, styles.titleLink)}
              to={`/${category.slug}/${post.slug}`}
              data-hook="post-list-item_link"
            >
              <PostTitle
                className={classNames(
                  titleFontClassName,
                  styles.title,
                  'forum-text-color',
                  'forum-link-hover-color',
                )}
                type={PostTitle.SMALL}
                title={post.title}
                query={query}
                post={post}
              />
            </Link>
            {post.isPinned && (
              <PinIcon
                className={classNames(styles.icon, 'icon-secondary-fill')}
                isPinned={post.isPinned}
                aria-label={t('post-list-item.pinned-post')}
              />
            )}
            {post.isCommentsDisabled && (
              <LockIcon
                className={classNames(styles.icon, 'icon-secondary-fill')}
                isLocked={post.isCommentsDisabled}
              />
            )}
            <NewContentIndicator
              className={styles.icon}
              count={post.isSeen === false && 1}
              hideNumber
            />
          </div>
          {this.renderFooter(owner)}
        </td>
        <td className={classNames(styles.count, styles.right)}>
          <CounterNumber initialValue={post.totalComments} entityId={post._id} totalComments />
        </td>
        <td className={classNames(styles.count, styles.right)}>
          <CounterNumber initialValue={post.likeCount} entityId={post._id} likeCount />
        </td>
        {isViewsCountEnabled && (
          <td className={classNames(styles.count, styles.right)}>
            <span data-hook="post-list-item__view-count">
              {showViewCount && <CounterNumber entityId={post._id} viewCount />}
            </span>
          </td>
        )}
        <td className={classNames(styles.left, styles.recentActivity)}>
          <PostListItemRecentActivity post={post} category={category} />
        </td>
        <td>
          <MoreButton id={moreButtonId}>
            <PostActions post={post} focusOnCloseId={moreButtonId} origin="feed_page" />
          </MoreButton>
        </td>
      </tr>
    );
  }
}

PostListItemClassic.propTypes = {
  t: PropTypes.func,
  category: PropTypes.object,
  post: PropTypes.object.isRequired,
  query: PropTypes.string,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  navigation: PropTypes.object,
  isViewsCountEnabled: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  isListHashtagsEnabled: PropTypes.bool,
  fetchPostPageData: PropTypes.func,
  showViewCount: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  category: getCategory(state, ownProps.post.categoryId),
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  isListHashtagsEnabled: getIsListHashtagsEnabled(state, host.style),
  fetchPostPageData: actions.fetchPostPageData,
  showViewCount: !isCacheableRendering(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
  withExperiment({
    isRecentActivityCatsEnabled: EXPERIMENT_RECENT_ACTIVITY_CATS,
  }),
)(PostListItemClassic);
