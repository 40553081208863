import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import {
  ROUTE_404,
  ROUTE_SEARCH,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_CATEGORY,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_DEV_PLAYGROUND,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_POST,
  ROUTE_POST_EDIT,
  ROUTE_COMMENT_DEEP_LINK,
} from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import App from '../app';
import NotFoundPage from '../not-found-page';
import ResultsPage from '../results-page';
import { getCurrentMatch } from '../../../common/router/router-selectors';
import { getIsCategorySelectEnabled } from '../../selectors/app-settings-selectors';
import HomePage from '../home-page';
import CategoryPage from '../category-page';
import CategoriesPageHeader from '../categories-page-header';
import CategoryPageHeader from '../category-page-header';
import InternalPageHeader, {
  InternalPageDesktopHeader,
  InternalPageCategorySelectDesktopHeader,
} from '../internal-page-header';
import PostPage from '../post-page';
import LoginPage from '../login-page';
import PostCreatePage from '../post-create-page';
import PostEditPage from '../post-edit-page';
import SearchHeader from '../search-header';
import AccountSuspendedPage from '../account-suspended-page';
import { SearchPageFiltersBar } from '../../components/filters-bar-mobile';
import DeepLinkLoader from '../../components/deep-link-loader';
import withDeviceType from '../../hoc/with-device-type';
import HeaderTitle from '../../components/header-title';
import { DevPlayground } from '../../components/dev-playground';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

const ROUTE_COMPONENT_MAP = {
  [ROUTE_404]: ({ params }) => {
    const header = <CategoriesPageHeader params={params} />;
    return (
      <App
        main={<NotFoundPage />}
        simpleHeader={header}
        simpleHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader />}
      />
    );
  },
  [ROUTE_SEARCH]: ({ params, isMobile }) => {
    const header = <SearchHeader params={params} />;
    const mobileHeader = isMobile ? <SearchPageFiltersBar /> : null;
    return (
      <App
        main={<ResultsPage />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={header}
        menu={mobileHeader}
      />
    );
  },
  [ROUTE_LOGIN]: () => <App main={<LoginPage />} desktopHeader={<InternalPageDesktopHeader />} />,
  [ROUTE_ACCOUNT_SUSPENDED]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        main={<AccountSuspendedPage />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader />}
      />
    );
  },
  [ROUTE_HOME]: ({ params, isCategorySelectEnabled }) => {
    return (
      <App
        main={<HomePage />}
        simpleHeader={<CategoriesPageHeader params={params} />}
        headroomHeaderMainPageDisabled={<CategoryPageHeader params={params} />}
        desktopHeader={
          isCategorySelectEnabled ? (
            <InternalPageCategorySelectDesktopHeader params={params} />
          ) : (
            <InternalPageDesktopHeader params={params} />
          )
        }
      />
    );
  },
  [ROUTE_CATEGORY]: ({ params, isCategorySelectEnabled }) => {
    return (
      <App
        main={<CategoryPage />}
        headroomHeader={<CategoryPageHeader params={params} />}
        headroomHeaderMainPageDisabled={<CategoryPageHeader params={params} />}
        desktopHeader={
          isCategorySelectEnabled ? (
            <InternalPageCategorySelectDesktopHeader params={params} />
          ) : (
            <InternalPageDesktopHeader params={params} />
          )
        }
      />
    );
  },
  [ROUTE_POST]: ({ params }) => {
    const header = (
      <InternalPageHeader
        params={params}
        title={<HeaderTitle titleOverride="post-page.header-title" />}
      />
    );
    return (
      <App
        main={<PostPage />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_CREATE_POST]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        main={<PostCreatePage />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_CREATE_QUESTION]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        main={<PostCreatePage />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_CATEGORY_CREATE_POST]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        main={<PostCreatePage />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_CATEGORY_CREATE_QUESTION]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        main={<PostCreatePage />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_POST_EDIT]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        main={<PostEditPage />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_COMMENT_DEEP_LINK]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        main={<DeepLinkLoader />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_DEV_PLAYGROUND]: () => {
    return <App main={<DevPlayground />} />;
  },
};

class Router extends React.PureComponent {
  render() {
    const { route, ...rest } = this.props;
    const Component = ROUTE_COMPONENT_MAP[route]
      ? ROUTE_COMPONENT_MAP[route]
      : () => <div>Route "{route}" - is not implemented</div>;
    return <Component {...rest} />;
  }
}

Router.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isCategorySelectEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const routing = getCurrentMatch(state);
  return {
    route: routing.route,
    pathname: routing.pathname,
    sectionUrl: routing.sectionUrl,
    params: routing.params,
    isCategorySelectEnabled: getIsCategorySelectEnabled(state, host.style),
  };
};

export default flowRight(connect(mapRuntimeToProps, [REDUCERS.ROUTER]), withDeviceType)(Router);
