import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Masonry from '../../../common/components/masonry';
import {
  CARD_SIZE_SMALL,
  CARD_SIZE_MEDIUM,
} from '@wix/communities-forum-client-commons/dist/src/constants/card-sizes';
import { getIsMultiplePostTypeCategory } from '../../services/categories-service';
import PostListItem from '../post-list-item';
import { SortingSelectCategoryPageDesktop } from '../sorting-select-category';
import { FilterSelectPostTypeDesktop } from '../filter-select-post-type';
import CreatePostButton from '../create-post-button/create-post-button';
import { getCardSize } from '../../selectors/app-settings-selectors';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFiltered from '../no-posts-filtered';
import MarkAllAsReadButton from '../mark-all-as-read-button';
import styles from './post-list-masonry-responsive.scss';

const TYPES = {
  [CARD_SIZE_SMALL]: 'small',
  [CARD_SIZE_MEDIUM]: 'medium',
};

const getMasonryBreakpointsByCardSize = cardSize => {
  if (cardSize === CARD_SIZE_SMALL) {
    return {
      default: 3,
      750: 2,
      600: 1,
    };
  } else if (cardSize === CARD_SIZE_MEDIUM) {
    return {
      default: 2,
      600: 1,
    };
  }
};

const renderCreateNewPostButton = (categorySlug, postTypes) => {
  return <CreatePostButton categorySlug={categorySlug} postTypes={postTypes} />;
};

const renderSortingSelect = () => (
  <div className={styles.selectContainer}>
    <SortingSelectCategoryPageDesktop />
  </div>
);

const renderFilterSelect = () => (
  <div className={styles.selectContainer}>
    <FilterSelectPostTypeDesktop />
  </div>
);

const PostListMasonryResponsive = ({
  category,
  posts,
  onLikeClick,
  showCreatePostAction,
  showCategoryLink,
  cardSize,
  uniquePostTypesInAllCategories,
  hasActiveFilter,
}) => {
  const className = classNames(styles.masonry);

  const renderControls = () => {
    const hasOnlyOnePostTypeCategory = uniquePostTypesInAllCategories.length === 1;
    const shouldShowFilter = getIsMultiplePostTypeCategory(category) && !hasOnlyOnePostTypeCategory;
    const postTypes = hasOnlyOnePostTypeCategory
      ? uniquePostTypesInAllCategories
      : category.postTypes;

    return (
      <div className={classNames(styles.controls)} data-hook="post-list-masonry-controls">
        <div className={styles.controlsSide}>
          {shouldShowFilter && renderFilterSelect()}
          {renderSortingSelect()}
        </div>
        <div className={styles.controlsSide}>
          <MarkAllAsReadButton className={styles.markAllAsRead} category={category} />
          {showCreatePostAction && renderCreateNewPostButton(category.slug, postTypes)}
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderControls()}
      {!posts.length && hasActiveFilter ? (
        <NoPostsFiltered />
      ) : (
        <Masonry
          columnClassName={styles.masonryColumn}
          containerWidth={960}
          breakpointCols={getMasonryBreakpointsByCardSize(cardSize)}
          className={className}
          data-hook="post-list"
        >
          {posts.map(post => (
            <div
              className={classNames(styles.listItem, 'post-list__post-list-item')}
              key={post._id}
              id={post._id}
              data-hook="post-list-item"
            >
              <PostListItem
                post={post}
                onLikeClick={onLikeClick}
                showCategoryLink={showCategoryLink}
                type={TYPES[cardSize]}
                showRecentActivity
              />
            </div>
          ))}
        </Masonry>
      )}
    </div>
  );
};

PostListMasonryResponsive.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  cardSize: PropTypes.number,
  hasActiveFilter: PropTypes.bool,
  uniquePostTypesInAllCategories: PropTypes.array,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  cardSize: getCardSize(state, host.style),
});

export default flowRight(connect(mapRuntimeToProps))(PostListMasonryResponsive);
