import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SIDEBAR } from '../../constants/bi-locations';
import Link from '../link/internal-link';
import ArrowSubcategoriesIcon from '../icons/arrow-subcategories-icon';
import styles from './post-page-sidebar-category-list-item.scss';
import NewContentIndicator from '../new-content-indicator';

const PostPageSidebarCategoryListItem = ({ category, subcategories }) => {
  const categoryLink = `/${category.slug}`;

  const renderSubcategories = () => {
    return (
      <div className={styles.subcategoryList}>
        {subcategories.map(subcategory => {
          const subCategoryLink = `/${subcategory.slug}`;
          return (
            <Link
              className={classNames(styles.link, 'forum-text-color', 'forum-link-hover-color')}
              to={subCategoryLink}
              biLocation={SIDEBAR}
            >
              <ArrowSubcategoriesIcon
                className={classNames(styles.subcategoryIcon, 'forum-icon-fill')}
              />
              <div className={styles.labelWrapper}>
                <span className={styles.label}>{subcategory.label}</span>
                <NewContentIndicator
                  className={styles.newPostIndicator}
                  count={subcategory.unseenPostsCount}
                  hideDot
                  hideWord
                />
              </div>
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Link
        className={classNames(styles.link, 'forum-text-color', 'forum-link-hover-color')}
        to={categoryLink}
        biLocation={SIDEBAR}
      >
        <div className={styles.labelWrapper}>
          <span className={styles.label}> {category.label}</span>
          <NewContentIndicator
            className={styles.newPostIndicator}
            count={category.unseenPostsCount}
            hideDot
            hideWord
          />
        </div>
      </Link>
      {subcategories && renderSubcategories()}
    </div>
  );
};

PostPageSidebarCategoryListItem.propTypes = {
  subcategories: PropTypes.array.isRequired,
  category: PropTypes.object.isRequired,
};

export default PostPageSidebarCategoryListItem;
