import { get, sortBy } from 'lodash';
import { UNCATEGORIZED_POSTS } from '../reducers/pagination';

export const getEntity = (state, entityType, paginationId) =>
  get(state.pagination, `${entityType}${paginationId ? `.${paginationId}` : ''}`);

export const getEntityCount = (state, entityType, paginationId) =>
  get(getEntity(state, entityType, paginationId), 'entityCount');

export const getPostEntityIdsForPage = (state, page, categoryId = UNCATEGORIZED_POSTS) =>
  get(state, `pagination.posts.${categoryId}.entitiesByPage.${page}`, []);

export const getPostEntityIds = (state, categoryId = UNCATEGORIZED_POSTS) => {
  const entitiesByPage = get(state, `pagination.posts.${categoryId}.entitiesByPage`, {});
  let postsIds = [];
  for (const index of sortBy(Object.keys(entitiesByPage))) {
    if (entitiesByPage[index]) {
      postsIds = postsIds.concat(entitiesByPage[index]);
    }
  }

  return postsIds;
};

export const getTotalPages = (state, entityType, paginationId, itemsPerPage) =>
  Math.max(Math.ceil(getEntityCount(state, entityType, paginationId) / itemsPerPage), 1);
