import { memoize } from 'lodash';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import AppRoot from './containers/app-root';
import InvalidAppStatePage from './components/invalid-app-state-page';
import { withWebpackPublicPath } from '../common/hoc/with-webpack-public-path';
import { withLayoutUpdate } from '../common/hoc/with-layout-update';
import { withInvalidAppState } from '../common/hoc/with-invalid-app-state';
import { withDevTools } from './components/dev-tools/with-dev-tools';
import { withSentryErrorBoundaryApp } from '../common/hoc/with-error-boundary';

const withStylesConfig = {
  cssPath: ['forum-app-viewer.stylable.bundle.css', 'forum-app-viewer.css'],
  enableMemoization: memoize.Cache !== WeakMap,
};

const FallbackPage = withStyles(InvalidAppStatePage, withStylesConfig);

export default {
  component: withSentryErrorBoundaryApp(
    withLayoutUpdate(
      withInvalidAppState(
        withWebpackPublicPath(withDevTools(withStyles(AppRoot, withStylesConfig))),
        FallbackPage,
      ),
    ),
    FallbackPage,
  ),
};
