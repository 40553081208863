import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { getSecondaryColor } from '../../services/get-colors';
import Textarea from 'react-textarea-autosize';
import styles from './input-text.scss';
import withTranslate from '../../../common/components/with-translate/with-translate';

class InputText extends Component {
  state = {
    hasFocus: false,
  };

  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.setState({ hasFocus: true });
    }
  }

  handleBlur() {
    this.setState({ hasFocus: false });
  }

  handleFocus() {
    this.setState({ hasFocus: true });
  }

  renderCounter() {
    const { value, maxLength, t } = this.props;
    const left = maxLength - value.length;
    if (maxLength) {
      return (
        <span
          className={classNames(styles.counter, { [styles.hasFocus]: this.state.hasFocus })}
          style={left === 0 ? { color: getSecondaryColor() } : null}
          aria-label={t('input-text.characters-left', { count: left })}
        >
          {left}
        </span>
      );
    }
  }

  render() {
    const { className, containerClassName, ...props } = this.props;
    return (
      <div className={classNames(styles.inputWrapper, containerClassName)}>
        <Textarea
          className={classNames(styles.input, className)}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          {...props}
        />
        {this.renderCounter()}
      </div>
    );
  }
}

InputText.propTypes = {
  t: PropTypes.func,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default withTranslate(InputText);
