import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import SiteLink from './site-link';
import NoopLink from './noop-link';
import { getCurrentUserSiteMemberId } from '../../../common/store/current-user/current-user-selectors';
import { getProfileLinkTemplate } from '../../../common/store/ma-navigation/ma-navigation-selectors';
import { buildLink } from '../../../common/store/ma-navigation/build-link';
import forMembersArea from '../../hoc/for-members-area';
import forUserPrivacyType from '../../hoc/for-user-privacy-type';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

const ProfileLink = ({
  user,
  currentMemberId,
  profileLinkTemplate,
  navigateToProfile,
  userEventsClickProfileLink,
  ...props
}) => {
  const handleGoToProfile = event => {
    event.preventDefault();
    event.stopPropagation();
    userEventsClickProfileLink({ isOwnProfile: user.siteMemberId === currentMemberId });
    navigateToProfile({ memberId: user.siteMemberId, memberSlug: user.slug });
  };
  return (
    <SiteLink
      onClick={handleGoToProfile}
      to={buildLink(profileLinkTemplate, user.slug)}
      {...props}
    />
  );
};

ProfileLink.propTypes = {
  user: PropTypes.object.isRequired,
  profileLinkTemplate: PropTypes.string,
  navigateToProfile: PropTypes.func,
  userEventsClickProfileLink: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  currentMemberId: getCurrentUserSiteMemberId(state),
  profileLinkTemplate: getProfileLinkTemplate(state),
  navigateToProfile: actions.navigateToProfile,
  userEventsClickProfileLink: actions.userEventsClickProfileLink,
});

export default forMembersArea(
  connect(mapRuntimeToProps, [REDUCERS.CURRENT_USER, REDUCERS.MA_NAVIGATION])(
    forUserPrivacyType(ProfileLink),
  ),
  NoopLink,
);
