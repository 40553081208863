import { merge } from 'lodash';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { DevToolsContext } from './dev-tools-context';

export function withDevTools(WrappedComponent) {
  class withDevTools extends React.Component {
    state = {
      stylePresets: {},
    };
    componentDidMount() {
      import('./style-presets').then(({ stylePresets }) => {
        this.setState({ stylePresets });
      });
    }
    applyStyleOverrides = host => {
      const { devToolsStore } = this.props;
      const { styleOverrides } = devToolsStore.state;
      return styleOverrides ? { ...host, style: merge(host.style, styleOverrides) } : host;
    };
    render() {
      const { devToolsStore } = this.props;
      const { stylePreset } = devToolsStore.state;

      return (
        <DevToolsContext.Provider value={devToolsStore}>
          <WrappedComponent
            {...this.props}
            host={this.applyStyleOverrides(
              stylePreset && this.state.stylePresets[stylePreset]
                ? { ...this.props.host, style: this.state.stylePresets[stylePreset].style }
                : this.props.host,
            )}
          />
        </DevToolsContext.Provider>
      );
    }
  }

  hoistNonReactStatics(withDevTools, WrappedComponent);
  const isDebug = typeof window !== 'undefined' && window.location.search.includes('debug=true');
  return process.env.NODE_ENV !== 'production' || isDebug ? withDevTools : WrappedComponent;
}
