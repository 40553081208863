import React from 'react';
import { connect } from '../../common/components/runtime-context';
import { isMemberAreaActive } from '@wix/communities-forum-client-commons/dist/src/selectors/member-area-selectors';

const forMembersArea = (ComponentMembersArea, ComponentNoMembersArea) => {
  const Component = ({ isMemberAreaActive, ...props }) => {
    const Component = isMemberAreaActive ? ComponentMembersArea : ComponentNoMembersArea;
    return <Component {...props} />;
  };

  const mapRuntimeToProps = state => ({
    isMemberAreaActive: isMemberAreaActive(state),
  });

  return connect(mapRuntimeToProps)(Component);
};

export default forMembersArea;
