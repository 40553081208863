import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import CommentActions from '../../containers/comment-actions';
import CommentAvatar from '../comment-avatar';
import MoreButton from '../more-button';
import TimeAgo from '../time-ago';
import BestAnswer from '../best-answer';
import { BulletSeparator } from '../separator';
import styles from './comment-header.scss';
import withDeviceType from '../../hoc/with-device-type';
import AvatarImage from '../avatar-image';
import MoreButtonMobile from '../more-button-mobile/more-button-mobile';
import { MODAL_TYPE_COMMENT_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';
import Interpolate from '../../../common/components/interpolate/interpolate';

const renderMoreButton = (
  doRender,
  comment,
  type,
  showMoreButtonEdit,
  showMoreButtonBestAnswer,
  isMobile,
) => {
  const moreButtonId = `more-button-${comment._id}`;

  if (doRender) {
    return (
      <div className={classNames(styles.moreButton, styles[type])}>
        {isMobile ? (
          <MoreButtonMobile
            type={MODAL_TYPE_COMMENT_ACTIONS}
            comment={comment}
            focusOnCloseId={moreButtonId}
            showEditButton={showMoreButtonEdit}
            showBestAnswerButton={showMoreButtonBestAnswer}
          />
        ) : (
          <MoreButton id={moreButtonId}>
            <CommentActions
              comment={comment}
              focusOnCloseId={moreButtonId}
              showEditButton={showMoreButtonEdit}
              showBestAnswerButton={showMoreButtonBestAnswer}
            />
          </MoreButton>
        )}
      </div>
    );
  }
};

const CommentHeader = ({
  type,
  comment,
  showMoreButton,
  showBestAnswer,
  showEditedDate,
  isMobile,
  showMoreButtonEdit,
  showMoreButtonBestAnswer,
}) => {
  const hasBadges = get(comment, 'owner.badges', []).length !== 0;
  return (
    <div
      className={classNames(
        styles.commentHeader,
        styles[type],
        { [styles.withMoreButton]: showMoreButton, [styles.isBestAnswer]: showBestAnswer },
        'comment-header',
      )}
    >
      {comment.owner && (
        <CommentAvatar
          user={comment.owner}
          date={comment.createdDate}
          type={AvatarImage.POST_PAGE}
          showDate={false}
          showUserInfoInline={!isMobile}
          truncateBadgeTitle={showEditedDate && comment.editedDate && showBestAnswer}
        />
      )}
      {isMobile ? (
        <span
          className={classNames(
            styles.commentTime,
            styles.postPageRevamp,
            hasBadges && styles.withBadges,
          )}
        >
          <BulletSeparator />
          <TimeAgo time={comment.createdDate} />
        </span>
      ) : (
        <span className={styles.commentTime}>
          {showBestAnswer && <BestAnswer showIconOnly={showEditedDate && comment.editedDate} />}
          {!isMobile && showBestAnswer && <BulletSeparator />}
          {!isMobile && <TimeAgo time={comment.createdDate} />}
          {showEditedDate && comment.editedDate && <BulletSeparator />}
          {showEditedDate && comment.editedDate && (
            <Interpolate i18nKey="comment.edited" timeAgo={<TimeAgo time={comment.editedDate} />} />
          )}
        </span>
      )}
      {renderMoreButton(
        showMoreButton,
        comment,
        type,
        showMoreButtonEdit,
        showMoreButtonBestAnswer,
        isMobile,
      )}
    </div>
  );
};

CommentHeader.SEARCH = 'search';

CommentHeader.defaultProps = {
  showBestAnswer: false,
  showMoreButtonEdit: true,
  showMoreButtonBestAnswer: true,
};

CommentHeader.propTypes = {
  type: PropTypes.string,
  comment: PropTypes.object.isRequired,
  showMoreButton: PropTypes.bool,
  showMoreButtonEdit: PropTypes.bool,
  showMoreButtonBestAnswer: PropTypes.bool,
  showBestAnswer: PropTypes.bool,
  showEditedDate: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default flowRight(withDeviceType)(CommentHeader);
