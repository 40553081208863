import { trim } from 'lodash';
import hasActiveUploads from './has-active-uploads';

const postFormSettings = {
  formName: 'post',
  resetOnUnmount: true,
  validate: values => {
    const errors = {};
    const { title, content } = values;

    if (!trim(title)) {
      errors.title = 'required';
    }

    if (content && hasActiveUploads(content)) {
      errors.content = 'uploading';
    }

    return errors;
  },
};

export default postFormSettings;
