import { get } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FastFormContext from './fast-form-context';
import removeFailedUploads from '../../../app/services/remove-failed-uploads';

// withFastForm({
//  formName: 'comments' || ownProps.formName,
//  formInitialValues: {} || ownProps.formInitialValues,
//  validate: (fields) => ({errors}),
//  resetOnUnmount: false
// })
// props.fastForm.changeValue('title')(value)
// props.fastForm.resetForm()
// props.fastForm.values.title
// props.fastForm.errors
// props.fastForm.isValid
// props.fastForm.isSubmitting
// props.fastForm.formCommitTime
// props.fastForm.formInitTime
// props.fastForm.submit() -> invokes onSubmit on ownProps or cb
// props.fastForm.stopSubmit() -> changes isSubmitting state to false

class FastFormProvider extends Component {
  state = {};

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.submitAction !== nextProps.submitAction ||
      this.props.onSubmit !== nextProps.onSubmit ||
      this.props.currentUerSiteMemberId !== nextProps.currentUerSiteMemberId ||
      this.state !== nextState
    );
  }

  static initEmptyFormState = () => ({
    values: {},
    errors: {}, // set on value change
    isValid: true,
    isSubmitting: false,
    formCommitTime: Date.now(),
    formInitTime: Date.now(),
  });

  static applyValueChangeToState = (formState, validate, name, value) => {
    const nextState = {
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
    };

    if (validate) {
      nextState.errors = validate(nextState.values) || {};
    }

    nextState.isValid = Object.keys(nextState.errors).length === 0;
    nextState.formCommitTime = Date.now();
    return nextState;
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.submitAction &&
      get(prevProps.submitAction, 'timestamp') !== get(this.props.submitAction, 'timestamp')
    ) {
      this.submitForm(this.props.submitAction.formName);
    }
  }

  getApi = (options, ownProps, config) => {
    const formName = ownProps.formName || options.formName;
    const initialValues = options.formInitialValues || ownProps.formInitialValues || {};

    let formState = this.state[formName];

    if (!config.skipInitialize && !formState) {
      formState = FastFormProvider.initEmptyFormState();
      for (const [key, value] of Object.entries(initialValues)) {
        formState = FastFormProvider.applyValueChangeToState(
          formState,
          options.validate,
          key,
          value,
        );
      }

      this.setState({ [formName]: formState });
    }

    if (!formState && config.skipInitialize) {
      formState = FastFormProvider.initEmptyFormState();
    }

    return {
      submit: cb => {
        return this.submitForm(
          formName,
          ownProps.onSubmit || cb,
          this.getApi(options, ownProps, config),
        );
      },
      stopSubmit: () => {
        const currentFormState = this.state[formName];

        if (!currentFormState) {
          return console.error(`cannot stop submit ${formName} - form doesn't exist`);
        }

        if (!currentFormState.isSubmitting) {
          return;
        }

        this.setState({
          [formName]: {
            ...currentFormState,
            isSubmitting: false,
          },
        });
      },
      changeValue: name => (value, cb) => {
        const currentFormState = this.state[formName] || formState;
        if (currentFormState.isSubmitting) {
          return;
        }
        const newValue = FastFormProvider.applyValueChangeToState(
          currentFormState,
          options.validate,
          name,
          get(value, 'target.value', value),
        );

        this.setState(
          {
            [formName]: newValue,
          },
          cb,
        );
      },
      resetForm: () => {
        this.resetForm(formName);
      },
      ...formState,
    };
  };

  resetForm = formName => {
    this.setState({ [formName]: undefined });
  };

  submitForm = (formName, cb, api) => {
    const currentFormState = this.state[formName];

    if (!currentFormState) {
      return console.error(`cannot submit ${formName} - form doesn't exist`);
    }

    if (currentFormState.isSubmitting) {
      return;
    }

    if (currentFormState.values.content) {
      currentFormState.values.content = removeFailedUploads(currentFormState.values.content);
    }

    const formState = {
      ...currentFormState,
      isSubmitting: true,
    };

    if (cb) {
      cb(formState, api);
    }

    this.setState({ [formName]: formState });
    this.props.onSubmit && this.props.onSubmit(formName, formState); // transmit to redux
  };

  render() {
    return (
      <FastFormContext.Provider value={{ getApi: this.getApi, resetForm: this.resetForm }}>
        {this.props.children}
      </FastFormContext.Provider>
    );
  }
}

FastFormProvider.propTypes = {
  submitAction: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default FastFormProvider;
