import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './desktop-header.scss';

const renderMenu = menu => {
  return menu && <div className={styles.menu}>{menu}</div>;
};

const DesktopHeader = ({ left, right, menu }) => {
  const containerClassName = classNames(styles.container, 'header-background-color');

  const headerClassName = classNames(styles.header);

  return (
    <div className={containerClassName} data-hook="desktop-header">
      <div className={headerClassName}>
        {left}
        {right}
      </div>
      {renderMenu(menu)}
    </div>
  );
};

DesktopHeader.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
};

export default DesktopHeader;
