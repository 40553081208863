import { flowRight } from 'lodash';
import { connect } from '../../common/components/runtime-context';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getDisplayName from '@wix/communities-forum-client-commons/dist/src/services/get-display-name';
import getLayoutName from '../services/get-layout-name';
import { getLayoutType } from '../selectors/layout-selectors';
import { getCardSize } from '../selectors/app-settings-selectors';

export default function withFontClassName(WrappedComponent) {
  const Wrapper = class extends Component {
    static displayName = `WithFontClassNames(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      layoutType: PropTypes.number,
      cardSize: PropTypes.number,
      siteColors: PropTypes.object,
    };

    render() {
      const {
        layoutType,
        cardSize,
        siteColors, // eslint-disable-line no-unused-vars
        ...otherProps
      } = this.props;
      const layoutName = getLayoutName(layoutType, cardSize);
      const fontClassNames = {
        titleFontClassName: `forum-title-${layoutName}-font`,
        contentFontClassName: `forum-content-${layoutName}-font`,
      };

      return <WrappedComponent {...fontClassNames} {...otherProps} />;
    }
  };

  hoistNonReactStatics(Wrapper, WrappedComponent);

  const mapRuntimeToProps = (state, ownProps, actions, host) => ({
    layoutType: getLayoutType(state, host.style),
    cardSize: getCardSize(state, host.style),
  });

  return flowRight(connect(mapRuntimeToProps))(Wrapper);
}
