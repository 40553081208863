import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import LikeButton from '../like-button/index';
import PostStats from '../post-stats/index';
import ProtectedButton from '../../containers/protected-button/index';
import withFontClassName from '../../hoc/with-font-class-name';
import withSettingsColor from '../../hoc/with-settings-color';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import styles from './post-excerpt-footer.scss';
import { LIKE_POST } from '../../constants/interactions';
import CounterNumber from '../counter-number';

export class PostExcerptFooter extends Component {
  handleLikeClick = () => {
    const { onLikeClick, post, userEventsClickLike } = this.props;
    userEventsClickLike({
      type: 'post',
      _id: post._id,
      isLiked: post.isLiked,
    });
    onLikeClick(post._id);
  };

  render() {
    const { post, contentFontClassName, textColorClassName, type } = this.props;
    return (
      <div className={classNames(contentFontClassName, styles[type])}>
        <div
          className={classNames(
            styles.container,
            styles[type],
            contentFontClassName,
            textColorClassName,
            'post-footer',
          )}
          data-hook="post-excerpt-footer"
        >
          <div className={styles.leftButtons}>
            <PostStats
              viewCount={post.viewCount}
              commentCount={post.totalComments}
              postId={post._id}
            />
          </div>
          <div className={styles.rightButtons}>
            <ProtectedButton
              onClick={this.handleLikeClick}
              className={classNames(styles.likeButton, contentFontClassName)}
              actionDetails={{ action: LIKE_POST, args: [post._id] }}
            >
              <span
                className={classNames(styles.likeCount)}
                data-hook="post-excerpt-footer__like-count"
              >
                <CounterNumber
                  tKey="comment.like-count"
                  initialValue={post.likeCount}
                  entityId={post._id}
                  likeCount
                />
              </span>
              <LikeButton isLiked={post.isLiked} likeCount={post.likeCount} />
            </ProtectedButton>
          </div>
        </div>
      </div>
    );
  }
}

PostExcerptFooter.propTypes = {
  post: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string,
  onLikeClick: PropTypes.func.isRequired,
  textColorClassName: PropTypes.string.isRequired,
  type: PropTypes.string,
  t: PropTypes.func,
  userEventsClickLike: PropTypes.func.isRequired,
};
const mapRuntimeToProps = (state, ownProps, actions) => ({
  userEventsClickLike: actions.userEventsClickLike,
});

// prettier-ignore
export default flowRight(
  withFontClassName,
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'textColorClassName',
    alpha: 1,
  }),
  connect(mapRuntimeToProps),
)(PostExcerptFooter);
