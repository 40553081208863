import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ScrollListener from '../scroll-listener';
import Button from '../button';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import appRect from '../../services/app-rect';
import styles from './load-more.scss';
import withTranslate from '../../../common/components/with-translate/with-translate';

class LoadMore extends Component {
  shouldLoadMore = () => {
    const { height, y } = appRect();
    if (!this.props.isLoading && window) {
      return window.innerHeight > height + y;
    }
    return false;
  };

  handleScroll = () => {
    if (this.shouldLoadMore()) {
      this.loadMore();
    }
  };

  loadMore = () => {
    this.props.loadMore();
  };
  render = () => {
    const {
      element: Element,
      children,
      remainingEntities,
      showButton,
      isLoading,
      t,
      ...props
    } = this.props;
    const passProps = omit(props, [
      'scroll',
      'loadMore',
      'isLoading',
      'pageStart',
      'threshold',
      'dispatch',
      'i18n',
      'tReady',
    ]);
    const hasMore = remainingEntities > 0;

    if (!hasMore) {
      return <Element {...passProps}>{children}</Element>;
    }
    return (
      <Element {...passProps}>
        {children}
        {!showButton && isLoading && <Loader />}
        {!showButton && <ScrollListener onScroll={this.handleScroll} />}
        {showButton && (
          <div className={styles.container}>
            <Button
              className={styles.button}
              onClick={this.loadMore}
              isLoading={isLoading}
              disabled={isLoading}
              isSecondary
            >
              {t('load-more.load', { postsLeft: remainingEntities })}
            </Button>
          </div>
        )}
      </Element>
    );
  };
}

LoadMore.propTypes = {
  element: PropTypes.string,
  children: PropTypes.node,
  remainingEntities: PropTypes.number,
  loadMore: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showButton: PropTypes.bool,
  pageStart: PropTypes.number,
  threshold: PropTypes.number,
};

LoadMore.defaultProps = {
  element: 'div',
  pageStart: 2,
  threshold: 500,
};

export default withTranslate(LoadMore);
