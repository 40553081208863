import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import ButtonTooltip from '../button-tooltip';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './button.scss';

const SHOW_TIMEOUT = 200;
const HIDE_TIMEOUT = 3000;

class Button extends Component {
  state = {
    isTooltipVisible: false,
  };

  componentWillUnmount() {
    this.hideTooltip();
  }

  showTooltip() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ isTooltipVisible: true }, () => {
        this.timeout = setTimeout(() => this.hideTooltip(), HIDE_TIMEOUT);
      });
    }, SHOW_TIMEOUT);
  }

  hideTooltip() {
    clearTimeout(this.timeout);
    this.setState({
      isTooltipVisible: false,
    });
  }

  handleMouseEnter = () => {
    this.showTooltip();
  };

  handleMouseLeave = () => {
    this.hideTooltip();
  };

  renderTooltip() {
    const { isTooltipVisible } = this.state;
    const { tooltipText } = this.props;

    if (!tooltipText) {
      return null;
    }

    return (
      <ButtonTooltip isVisible={isTooltipVisible} target={this}>
        {tooltipText}
      </ButtonTooltip>
    );
  }

  render() {
    const {
      component: Component,
      className,
      containerClassName,
      contentFontClassName,
      isSecondary,
      isMuted,
      isLoading,
      isSmall,
      isShort,
      isExtraSmall,
      isUnderlined,
      children,
      isBorderless,
      isAutoWidth,
      isExtraCompact,
      ...otherProps
    } = this.props;
    const isPrimary = !isSecondary;
    const buttonClassName = classNames(
      styles.button,
      className,
      contentFontClassName,
      isPrimary ? 'button-background-color' : 'button-border-color',
      isPrimary ? 'button-primary-text-color' : 'button-color',
      {
        [styles.secondary]: isSecondary,
        [styles.borderless]: isBorderless,
        [styles.isMuted]: isMuted,
        [styles.extraSmall]: isExtraSmall,
        [styles.small]: isSmall,
        [styles.short]: isShort,
        [styles.underline]: isUnderlined,
        [styles.autoWidth]: isAutoWidth,
        [styles.extraCompact]: isExtraCompact,
      },
    );
    const componentProps = omit(
      otherProps,
      'currentUser',
      'dispatch',
      'isAuthenticated',
      'isBlocked',
      'isDemoMode',
      'isOwner',
      'openModal',
      'titleFontClassName',
      'tooltipText',
      'viewMode',
      'forPublicUser',
      'isPrivate',
      'isLoading',
      'isSmall',
      'tReady',
      'i18n',
      'requestLoginMode',
      'beforeRequestLogin',
    );

    return (
      <div className={classNames(styles.container, containerClassName)}>
        <Component
          className={buttonClassName}
          {...componentProps}
          onMouseEnter={this.handleMouseEnter}
          onMouseMove={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <div>
            {isLoading && (
              <Loader
                bubbleClassName={
                  isPrimary ? 'button-loader-background-primary-color' : 'button-background-color'
                }
                small
              />
            )}
          </div>
          {!isLoading && <span className={styles.wrapper}>{children}</span>}
        </Component>
        {this.renderTooltip()}
      </div>
    );
  }
}

Button.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  isSecondary: PropTypes.bool,
  isMuted: PropTypes.bool,
  isLoading: PropTypes.bool,
  component: PropTypes.any,
  children: PropTypes.node,
  tooltipText: PropTypes.string,
  isSmall: PropTypes.bool,
  isShort: PropTypes.bool,
  isExtraSmall: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  isBorderless: PropTypes.bool,
  isAutoWidth: PropTypes.bool,
  isExtraCompact: PropTypes.bool,
};

Button.defaultProps = {
  component: 'button',
};

export default withFontClassName(Button);
