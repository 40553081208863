import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { HorizontalSeparator } from '../separator';
import CategoryListItem from './category-list-classic-item-desktop';
import SubcategoryListItem from './subcategory-list-classic-item-desktop';
import { getSubcategories } from '../../../common/selectors/categories-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getIsShowSubcategoriesEnabled } from '../../selectors/app-settings-selectors';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import styles from './category-list-classic-desktop.scss';

const ListItemFragment = ({
  category,
  subcategories,
  isShowSubcategoriesEnabled,
  isViewsCountEnabled,
  borderWidth,
}) => (
  <div
    style={{ borderWidth }}
    className={classNames(
      styles.item,
      'forum-card-border-color',
      'forum-card-background-color',
      'forum-text-color',
    )}
  >
    <CategoryListItem category={category} isViewsCountEnabled={isViewsCountEnabled} />
    {isShowSubcategoriesEnabled &&
      subcategories.length > 0 &&
      subcategories.map(subcategory => (
        <React.Fragment key={subcategory._id}>
          <HorizontalSeparator />
          <SubcategoryListItem category={subcategory} isViewsCountEnabled={isViewsCountEnabled} />
        </React.Fragment>
      ))}
  </div>
);

const mapRuntimeToProps = (state, { category }, actions, host) => ({
  subcategories: getSubcategories(state, category._id),
  isShowSubcategoriesEnabled: getIsShowSubcategoriesEnabled(state, host.style),
});

const ListItem = flowRight(
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES]),
  withCardBorderWidth,
)(ListItemFragment);

const CategoryListClassicDesktop = ({ categories, isViewsCountEnabled }) => (
  <div className={styles.container}>
    {categories.map(category => (
      <ListItem key={category._id} category={category} isViewsCountEnabled={isViewsCountEnabled} />
    ))}
  </div>
);

CategoryListClassicDesktop.propTypes = {
  categories: PropTypes.array.isRequired,
  isViewsCountEnabled: PropTypes.bool,
};

export default CategoryListClassicDesktop;
