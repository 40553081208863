import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import ProtectedButton from '../../containers/protected-button';
import ReplyButton from '../reply-button';
import { LIKE_COMMENT } from '../../constants/interactions';
import LikeButton from '../like-button';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import withDeviceType from '../../hoc/with-device-type';
import withAuth from '../../hoc/with-auth';
import CommentVote from '../comment-vote';
import styles from './comment-footer.scss';
import { WhoLikedPopover } from '../who-liked';
import { SolvedIconInversed } from '../icons/solved-icon-inversed';
import { getPost } from '../../selectors/post-selectors';
import { geCommentLikeCount } from '../../selectors/comment-selectors';

class CommentFooter extends Component {
  handleLikeClick = e => {
    const {
      comment,
      post,
      onLikeClick,
      fetchComment,
      interactionStarted,
      userEventsClickLike,
    } = this.props;
    userEventsClickLike({
      type: 'comment',
      _id: comment._id,
      isLiked: comment.isLiked,
    });
    e.preventDefault();
    if (onLikeClick) {
      interactionStarted(LIKE_COMMENT);
      onLikeClick({ postId: comment.postId, commentId: comment._id });
      setTimeout(() => {
        fetchComment({ postId: comment.postId, commentId: comment._id, postType: post.postType });
      }, 1500);
    }
  };

  handleReplyClick = e => {
    const { comment, onReplyClick } = this.props;
    e.preventDefault();
    if (onReplyClick) {
      onReplyClick(comment._id);
    }
  };

  renderReplyLink = () => {
    const { showReplyLink, comment } = this.props;

    return showReplyLink ? (
      <span className={styles.replyLinkContainer}>
        <ReplyButton
          onClick={this.handleReplyClick}
          dataHook="comment-footer__reply"
          type={'reply'}
          userName={comment.owner && comment.owner.name}
        />
      </span>
    ) : (
      <div />
    );
  };

  markBestAnswer = () => {
    const { addBestAnswer, comment, buttonClicked } = this.props;
    addBestAnswer(comment.postId, comment._id);
    buttonClicked({
      isMarked: true,
      name: 'mark_as_best_answer',
      origin: 'hover_box',
      postId: comment.postId,
      commentId: comment._id,
    });
  };

  renderLikeButton = () => {
    const { showLikeButton, contentFontClassName, comment, forPublicUser, likeCount } = this.props;

    if (showLikeButton) {
      return (
        <div className={styles.likeContainer}>
          <ProtectedButton
            onClick={forPublicUser(this.handleLikeClick, { preventDefault: true })}
            className={classNames(styles.likeButton, contentFontClassName)}
            actionDetails={{
              action: LIKE_COMMENT,
              args: [comment._id, comment.postId],
            }}
          >
            <LikeButton
              isLiked={comment.isLiked}
              likeCount={likeCount}
              isDisabled={comment.isLikeInProgress}
            />
          </ProtectedButton>
          <WhoLikedPopover
            type={comment.parentId ? 'reply' : 'comment'}
            commentId={comment._id}
            postId={comment.postId}
            totalItemCount={likeCount}
            initialItems={comment.likes}
          />
        </div>
      );
    }
  };

  render = () => {
    const { comment, t, type, showMarkAsBestAnswerButton, isMobile, showVoteButtons } = this.props;
    const containerClass = classNames(
      styles.container,
      styles[type],
      'forum-text-color',
      'comment-footer',
    );

    return (
      <div className={containerClass}>
        {showVoteButtons && (
          <div className={styles.commentVote}>
            <CommentVote comment={comment} />
          </div>
        )}
        {isMobile ? this.renderLikeButton() : this.renderReplyLink()}
        {isMobile ? this.renderReplyLink() : this.renderLikeButton()}
        {!isMobile && showMarkAsBestAnswerButton && (
          <button
            onClick={this.markBestAnswer}
            className={classNames(
              styles.markBestAnswerButton,
              'forum-icon-fill',
              'forum-text-color',
              'button-color-hover-fill',
              'button-color-hover',
            )}
            data-hook="quick-best-answer"
          >
            <SolvedIconInversed className={styles.markBestAnswerButtonIcon} />
            {t('comment-actions.mark-as-solved')}
          </button>
        )}
      </div>
    );
  };
}

CommentFooter.SEARCH = 'search';
CommentFooter.MEMBERS_AREA = 'membersArea';

CommentFooter.propTypes = {
  comment: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  mainColor: PropTypes.string,
  onLikeClick: PropTypes.func,
  onReplyClick: PropTypes.func,
  showReplyLink: PropTypes.bool,
  isMobile: PropTypes.bool,
  contentFontClassName: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func,
  interactionStarted: PropTypes.func,
  showLikeButton: PropTypes.bool,
  userEventsClickLike: PropTypes.func.isRequired,
  fetchComment: PropTypes.func.isRequired,
  showVoteButtons: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  interactionStarted: actions.interactionStarted,
  userEventsClickLike: actions.userEventsClickLike,
  addBestAnswer: actions.addBestAnswer,
  buttonClicked: actions.buttonClicked,
  fetchComment: actions.fetchComment,
  post: getPost(state, ownProps.comment.postId),
  likeCount: geCommentLikeCount(state, ownProps.comment._id, ownProps.comment.postId),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withAuth,
  withDeviceType,
)(CommentFooter);
