export const DELETE_POST_REQUEST = 'post/DELETE_REQUEST';
export const DELETE_POST_SUCCESS = 'post/DELETE_SUCCESS';
export const DELETE_POST_FAILURE = 'post/DELETE_FAILURE';

export function deletePost(postId) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: DELETE_POST_REQUEST,
    });

    const promise = request.delete(`/posts/${postId}`);

    return promise
      .then(() =>
        dispatch({
          type: DELETE_POST_SUCCESS,
          payload: postId,
        }),
      )
      .catch(() =>
        dispatch({
          type: DELETE_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
