import { flowRight, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
// import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';

import withTranslate from '../../../common/components/with-translate/with-translate';
import withAuth from '../../hoc/with-auth';
import IconButton from '../icon-button';
import { NotificationIcon } from '../icons/notification-icon';
// import { getCount } from '../../selectors/notification-selectors';
// import styles from './notification-button.scss';

const NotificationButton = ({ bubbleClassName, className, count, t, ...otherProps }) => {
  // const bubbleCssClass = classNames(styles.bubble, 'notification-button__bubble', bubbleClassName);
  return (
    <IconButton
      className={classNames('notification-button', className)}
      icon={NotificationIcon}
      label={t('breadcrumbs.notifications')}
      aria-label={t('breadcrumbs.notifications')}
      {...omit(otherProps, ['dispatch', 'i18n'])}
    ></IconButton>
  );
};

NotificationButton.propTypes = {
  bubbleClassName: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default flowRight(withTranslate, withAuth)(NotificationButton);
