export const CATEGORIES_MANAGER_OPEN_MAIN_WINDOW = 'CATEGORIES_MANAGER_OPEN_MAIN_WINDOW';
export const CATEGORIES_MANAGER_EDIT_CATEGORY = 'CATEGORIES_MANAGER_EDIT_CATEGORY';
export const CATEGORIES_MANAGER_CREATE_CATEGORY = 'CATEGORIES_MANAGER_CREATE_CATEGORY';
export const CATEGORIES_MANAGER_DELETE_CATEGORY = 'CATEGORIES_MANAGER_DELETE_CATEGORY';
export const CATEGORIES_MANAGER_UPDATE_CATEGORY = 'CATEGORIES_MANAGER_UPDATE_CATEGORY';
export const CATEGORIES_MANAGER_UPDATE_FORUM_DATA = 'CATEGORIES_MANAGER_UPDATE_FORUM_DATA';
export const CATEGORIES_MANAGER_SHOW_LOADER = 'CATEGORIES_MANAGER_SHOW_LOADER';
export const CATEGORIES_MANAGER_HIDE_LOADER = 'CATEGORIES_MANAGER_HIDE_LOADER';
export const CATEGORIES_MANAGER_FLOW_TYPE_CREATE = 'create';
export const CATEGORIES_MANAGER_FLOW_TYPE_EDIT = 'edit';
