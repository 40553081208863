import PropTypes from 'prop-types';
import React from 'react';
import styles from './best-answer.scss';
import { SolvedIcon } from '../icons/solved-icon';

const BestAnswerMobile = () => (
  <div className={styles.container} data-hook="best-answer">
    <SolvedIcon className="button-fill" />
  </div>
);

export default BestAnswerMobile;
