import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import withDividerColor from '../../hoc/with-divider-color';
import styles from './load-more-mobile-pagination.scss';

class LoadMoreMobilePagination extends Component {
  render() {
    const { hasMore, isLoading, borderBottom } = this.props;
    return hasMore ? (
      <div
        onClick={this.props.loadMore}
        style={{ borderColor: this.props.dividerColor }}
        className={classNames(
          styles.loadAction,
          'forum-text-color',
          borderBottom ? styles.borderBottom : '',
        )}
        data-hook="mobile-load-more"
      >
        {isLoading ? <Loader /> : this.props.loadActionText}
      </div>
    ) : null;
  }
}

LoadMoreMobilePagination.propTypes = {
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  loadActionText: PropTypes.string,
  dividerColor: PropTypes.string,
  borderBottom: PropTypes.bool,
};

export default withDividerColor(LoadMoreMobilePagination);
