import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './masonry.scss';

class Masonry extends React.Component {
  constructor(props) {
    super(props);

    let columnCount;
    if (this.props.breakpointCols && this.props.breakpointCols.default) {
      columnCount = this.props.breakpointCols.default;
    } else {
      columnCount = 2;
    }

    this.state = {
      columnCount,
    };
  }

  componentDidMount() {
    this.reCalculateColumnCount();
  }

  componentDidUpdate() {
    this.reCalculateColumnCount();
  }

  reCalculateColumnCount = () => {
    const containerWidth = this.props.containerWidth;
    const breakpointColsObject = this.props.breakpointCols;

    let matchedBreakpoint = Infinity;
    let columns = breakpointColsObject.default || 2;

    for (const breakpoint in breakpointColsObject) {
      const optBreakpoint = parseInt(breakpoint);
      const isCurrentBreakpoint = optBreakpoint > 0 && containerWidth <= optBreakpoint;

      if (isCurrentBreakpoint && optBreakpoint < matchedBreakpoint) {
        matchedBreakpoint = optBreakpoint;
        columns = breakpointColsObject[breakpoint];
      }
    }

    columns = Math.max(1, parseInt(columns) || 1);

    if (this.state.columnCount !== columns) {
      this.setState({
        columnCount: columns,
      });
    }
  };

  itemsInColumns = () => {
    const currentColumnCount = this.state.columnCount;
    const itemsInColumns = new Array(currentColumnCount);
    const items = this.props.children || [];

    for (let i = 0; i < items.length; i++) {
      const columnIndex = i % currentColumnCount;

      if (!itemsInColumns[columnIndex]) {
        itemsInColumns[columnIndex] = [];
      }

      itemsInColumns[columnIndex].push(items[i]);
    }
    return itemsInColumns;
  };

  renderColumns = () => {
    const { column, columnClassName } = this.props;
    const childrenInColumns = this.itemsInColumns();
    const w = `${100 / childrenInColumns.length}%`;

    return childrenInColumns.map((items, i) => {
      return (
        <div key={i} className={columnClassName} style={{ width: w }} {...column}>
          {items}
        </div>
      );
    });
  };

  render() {
    const { breakpointCols, columnClassName, column, className, ...wrapperProps } = this.props;

    return (
      <div className={classNames(styles.container, className)} {...wrapperProps}>
        {this.renderColumns()}
      </div>
    );
  }
}

Masonry.propTypes = {
  breakpointCols: PropTypes.object,
  columnClassName: PropTypes.string,
  className: PropTypes.string,
  containerWidth: PropTypes.number,
};

Masonry.defaultProps = {
  breakpointCols: {},
  containerWidth: Infinity,
};

export default Masonry;
