import PropTypes from 'prop-types';
import React from 'react';
import Highlighter from '../highlighter';
import contentStateToString from '../../services/content-state-to-string';
import styles from './simple-renderer.scss';

const Renderer = ({ contentState, query, isContentConverted, clamp }) => {
  const content = isContentConverted ? contentState : contentStateToString(contentState);
  return (
    <div
      className={styles.content}
      style={clamp > 0 ? { WebkitLineClamp: clamp, MozLineClamp: clamp } : {}}
    >
      {query ? <Highlighter text={content} query={query} /> : content}
    </div>
  );
};

Renderer.propTypes = {
  type: PropTypes.string,
  contentState: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  query: PropTypes.string,
  isContentConverted: PropTypes.bool,
  clamp: PropTypes.number,
};

export default Renderer;
