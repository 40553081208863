import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../../components/modals/categories-manager/categories-manager-modal-type';
import { MODAL_TYPE_DELETE_CATEGORY } from '../../components/modals/delete-category-modal/delete-category-modal-type';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from '../../components/modals/unfollow-category-modal/unfollow-category-modal-type';
import { DeleteIcon } from '../../components/icons/delete-icon';
import { EditIcon } from '../../components/icons/edit-icon';
import { GearIcon } from '../../components/icons/gear-icon';
import ActionButton from '../../components/action-button';
import { getCategoryCount } from '../../../common/selectors/categories-selectors';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withAuth from '../../hoc/with-auth';
import ensureAuth from '../../hoc/ensure-auth';
import { LockEmptyIcon } from '../../components/icons/lock-empty-icon';
import { MANAGE_MEMBERS } from '../../constants/categories-manager-section-types';
import { NotificationIcon } from '../../components/icons/notification-icon';
import { NotificationFollowingIcon } from '../../components/icons/notification-following-icon';
import ActionsDivider from '../../components/actions-divider';
import { CATEGORIES_MANAGER_EDIT_CATEGORY } from '../../actions/categories-manager-actions-constants';
import { THREE_DOT_ACTIONS } from '../../constants/bi-locations';
import ProfileActionsAddon from '../../components/profile-actions-addon/profile-actions-addon';
import styles from './category-actions.scss';
import { CheckMarkIcon } from '../../components/icons/check-mark-icon';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { isFollowButtonVisible } from '../../services/is-follow-button-visible';

const ActionButtonAuth = ensureAuth(ActionButton);

export function doesUserHavePermissionsToSeeCategoryActions(can, category) {
  // the logic is that everyone can see subscribe button expect those who cannot access category. Edit check is just in case for owners :)
  return (
    can('subscribe', 'category', category) ||
    (can('list', 'category', category) && !isPrivate(category)) ||
    can('edit', 'category', category)
  );
}

export class CategoryActions extends Component {
  componentDidMount() {
    this.props.actionsOpened({ type: null });
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: null });
  }

  handleSubscribe = () => {
    const {
      category: { _id },
      subscribe,
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 1 });

    return subscribe(_id);
  };

  handleUnsubscribe = () => {
    const {
      category: { _id },
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'unfollow_category', flag: 0 });

    this.props.openModal(MODAL_TYPE_UNFOLLOW_CATEGORY, { categoryId: _id });
  };

  handleEdit = () => {
    this.props.setBiLocation(THREE_DOT_ACTIONS, CATEGORIES_MANAGER_EDIT_CATEGORY);
    this.props.buttonClicked({ action: 'edit_category' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, { categoryId: this.props.category._id });
  };

  handleDelete = () => {
    this.props.buttonClicked({ action: 'delete_category' });
    this.props.openModal(MODAL_TYPE_DELETE_CATEGORY, { categoryId: this.props.category._id });
  };

  handleManageCategories = () => {
    this.props.buttonClicked({ action: 'manage_category' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, { categoryId: null });
  };

  markPostsAsRead = () => this.props.markPostsAsRead(this.props.category._id);

  render() {
    const {
      canRender,
      category,
      t,
      forPublicUser,
      isOneCategoryRemaining,
      isAuthenticated,
      hideFollowButton,
      showProfileActions,
      showMarkPostsAsRead,
    } = this.props;
    const followButton =
      !hideFollowButton &&
      canRender(
        [
          ['subscribe', 'category', category],
          ['list', 'category', category],
        ],
        ([canSubscribe]) => {
          if (!isFollowButtonVisible(category, canSubscribe)) {
            // All users can see follow button for all categories, except private ones
            return null;
          }

          const action = category.isSubscribed ? this.handleUnsubscribe : this.handleSubscribe;
          const ActionComponent = canSubscribe ? ActionButton : ActionButtonAuth;
          return (
            <ActionComponent
              dataHook="category-actions__subscriptions"
              onClick={canSubscribe ? action : forPublicUser(action)}
            >
              {category.isSubscribed ? (
                <NotificationFollowingIcon
                  strokeClass={styles.notificationsStroke}
                  fillClass={styles.notificationsFill}
                />
              ) : (
                <NotificationIcon />
              )}
              {t(
                category.isSubscribed
                  ? 'category-actions.unsubscribe-category'
                  : 'category-actions.subscribe-category',
              )}
            </ActionComponent>
          );
        },
      );

    const markPostsAsReadButton =
      this.props.isRecentActivityCatsEnabled && showMarkPostsAsRead && isAuthenticated ? (
        <ActionButton dataHook="category-actions__edit" onClick={this.markPostsAsRead}>
          <CheckMarkIcon className={styles.buttonIcon} />
          {t('recent-activity.mark-all-as-read')}
        </ActionButton>
      ) : null;

    const editButton = canRender([['edit', 'category']], () => (
      <ActionButton dataHook="category-actions__edit" onClick={this.handleEdit}>
        <EditIcon />
        {t('category-actions.edit-category')}
      </ActionButton>
    ));

    const deleteButton = canRender(
      [['delete', 'category']],
      () =>
        !isOneCategoryRemaining && (
          <ActionButton dataHook="category-actions__delete" onClick={this.handleDelete}>
            <DeleteIcon />
            {t('category-actions.delete-category')}
          </ActionButton>
        ),
    );

    const manageCategoriesButton = canRender([['edit', 'category']], () => {
      return (
        <ActionButton dataHook="category-actions__manage" onClick={this.handleManageCategories}>
          <GearIcon />
          {t('breadcrumbs-actions.manage-categories')}
        </ActionButton>
      );
    });

    const manageMembersButton = canRender([['edit', 'category']], () => {
      return (
        <ActionButton
          dataHook="category-actions__manage-members"
          onClick={this.handleManageMembers}
        >
          <LockEmptyIcon />
          {t('category-actions.manage-category-members')}
        </ActionButton>
      );
    });

    const profileActions = showProfileActions && <ProfileActionsAddon />;

    const shouldRenderDivider =
      (followButton || editButton || deleteButton) &&
      (manageCategoriesButton || showProfileActions);
    return (
      <div>
        {followButton}
        {markPostsAsReadButton}
        {editButton}
        {manageMembersButton}
        {deleteButton}
        {showProfileActions && manageCategoriesButton}
        {shouldRenderDivider && <ActionsDivider />}
        {profileActions}
        {!showProfileActions && manageCategoriesButton}
      </div>
    );
  }

  handleManageMembers = () => {
    this.props.setBiLocation(THREE_DOT_ACTIONS, CATEGORIES_MANAGER_EDIT_CATEGORY);
    this.props.buttonClicked({ action: 'manage_category_members' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, {
      categoryId: this.props.category._id,
      openPanels: [MANAGE_MEMBERS],
    });
  };
}

CategoryActions.propTypes = {
  category: PropTypes.object.isRequired,
  openModal: PropTypes.func,
  subscribe: PropTypes.func,
  canRender: PropTypes.func,
  actionsOpened: PropTypes.func,
  actionsClosed: PropTypes.func,
  buttonClicked: PropTypes.func,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  isOneCategoryRemaining: PropTypes.bool,
  hideFollowButton: PropTypes.bool,
  showProfileActions: PropTypes.bool,
  showMarkPostsAsRead: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  setBiLocation: PropTypes.func,
};

const mapRuntimeToProps = (state, { category }, actions, host) => {
  return {
    isOneCategoryRemaining: getCategoryCount(state) <= 1,
    subscribe: actions.subscribeToCategory,
    openModal: actions.openModal,
    actionsOpened: actions.actionsOpened,
    actionsClosed: actions.actionsClosed,
    setBiLocation: actions.setBiLocation,
    markPostsAsRead: actions.markPostsAsRead,
    buttonClicked: data =>
      actions.buttonClicked({
        name: 'action_click',
        type: 'post',
        ...data,
        categoryId: category._id,
      }),
  };
};

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withTranslate,
  withAuth,
  withExperiment({
    isRecentActivityCatsEnabled: EXPERIMENT_RECENT_ACTIVITY_CATS,
  }),
)(CategoryActions);
