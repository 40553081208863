import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Breadcrumbs from '../../components/breadcrumbs';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import SearchInput from '../search-input';
import DesktopHeader from '../desktop-header';
import { connect } from '../../../common/components/runtime-context';
import { getLocation } from '../../../common/store/location/location-selectors';

const renderSearchInput = () => (
  <SearchInput
    inputClassName="desktop-header-search-text-color desktop-header-search-font"
    borderClassName="desktop-header-search-border-background-color"
    iconClassName={classNames('desktop-header-search-icon-fill', 'button-hover-fill')}
  />
);

const InternalPageDesktopHeader = ({ routes, params, ...props }) => (
  <DesktopHeader
    left={<Breadcrumbs routes={routes} params={params} />}
    right={<BreadcrumbsWidget searchInput={renderSearchInput()} params={params} />}
    {...props}
  />
);

InternalPageDesktopHeader.propTypes = {
  // routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapRuntimeToProps = state => ({
  location: getLocation(state),
});

export default flowRight(connect(mapRuntimeToProps))(InternalPageDesktopHeader);
