import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../../components/modals/categories-manager/categories-manager-modal-type';
import Button from '../../components/button';
import { GearIcon } from '../../components/icons/gear-icon';
import ProfileActionsButton from '../../components/profile-actions-button';
import UserAvatarImage from '../../components/user-avatar-image';
import NotificationButton from '../../components/notification-button';
import MoreButton from '../../components/more-button';
import MoreIconCenter from '../../components/icons/more-icon-center';
import PrivateProfileActions from '../private-profile-actions';
import CategoryActions from '../category-actions';
import NotificationsLink from '../../components/link/notifications-link';
import preventClickInDemoMode from '../../hoc/prevent-click-in-demo-mode';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withAuth from '../../hoc/with-auth';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getViewMode } from '../../../common/store/basic-params/basic-params-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import { getIsLoginWidgetEnabled } from '../../selectors/app-settings-selectors';
import {
  getCategoryBySlug,
  getAllCategories,
} from '../../../common/selectors/categories-selectors';
import withPermissions from '../../hoc/with-permissions';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './breadcrumbs-widget.scss';
import { keepFocus } from '../../services/keep-focus';
import { doesUserHavePermissionsToSeeCategoryActions } from '../category-actions/category-actions';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

const ProtectedButton = preventClickInDemoMode(Button);

export class BreadcrumbsWidget extends Component {
  renderLogin = () => (
    <div className={styles.container}>
      {this.renderSearchInput()}
      {this.renderLoginButton()}
    </div>
  );

  renderMainWidget = () => (
    <div className={styles.container}>
      {this.renderSearchInput()}
      <div className={styles.right}>
        {this.renderCategoriesManagerButton()}
        {this.renderNotifications()}
        {this.renderActions()}
        {this.renderProfile()}
      </div>
    </div>
  );

  requestLogin = () => this.props.requestLogin();

  renderLoginButton = () => {
    const { isLoginWidgetEnabled, contentFontClassName } = this.props;
    if (!isLoginWidgetEnabled) {
      return null;
    }

    return (
      <ProtectedButton
        className={styles.loginButton}
        isSecondary
        onClick={this.requestLogin}
        data-hook="login-button"
        contentFontClassName={contentFontClassName}
      >
        {this.props.t('header.sign-up-button')}
      </ProtectedButton>
    );
  };

  renderNotifications = () => {
    const { isLoginWidgetEnabled } = this.props;
    return isLoginWidgetEnabled ? (
      <NotificationButton
        className={styles.notificationButton}
        component={NotificationsLink}
        iconClassName={classNames('breadcrumbs-icon-fill', 'button-hover-fill')}
        bubbleClassName={classNames(
          'forum-header-border-color',
          'button-primary-text-color',
          'button-background-color',
          styles.notificationBubble,
        )}
        data-hook="notification-button"
      />
    ) : null;
  };

  renderProfile = () => {
    const { currentUser, isLoginWidgetEnabled } = this.props;

    if (!isLoginWidgetEnabled) {
      return null;
    }

    const icon = (
      <button
        className={classNames(styles.avatarImage)}
        aria-label={this.props.t('breadcrumbs.profile')}
        data-hook="avatar-image"
      >
        <UserAvatarImage type="widget" user={this.props.currentUser} />
      </button>
    );
    return (
      <div className={styles.profile}>
        <ProfileActionsButton
          actionsContainerClassName={styles.profileActions}
          position={ProfileActionsButton.POSITION_RIGHT}
          icon={icon}
        >
          <PrivateProfileActions user={currentUser} />
        </ProfileActionsButton>
      </div>
    );
  };

  renderCategoriesManagerButton = () => {
    return this.props.canRender(
      [['delete', 'category'], ['edit, category'], ['create', 'category']],
      () => (
        <button
          onClick={() => {
            this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, { categoryId: null });
            this.props.buttonClicked({ name: 'categories_settings_cog' });
          }}
          className={classNames(
            styles.categoriesManagerButton,
            'breadcrumbs-icon-fill',
            'button-hover-fill',
          )}
          data-hook="categories-manager-button"
          aria-label={this.props.t('open-manage-categories-modal-button')}
        >
          <GearIcon className={classNames(styles.categoriesManagerIcon)} />
        </button>
      ),
    );
  };

  renderActions = () => {
    const { category, can } = this.props;
    if (!category._id) {
      return null;
    }

    if (!doesUserHavePermissionsToSeeCategoryActions(can, category)) {
      return null;
    }

    const icon = <MoreIconCenter />;
    return (
      <div className={styles.actions}>
        <MoreButton icon={icon} iconClassName="breadcrumbs-icon-fill">
          <CategoryActions category={category} />
        </MoreButton>
      </div>
    );
  };

  renderSearchInput = () => (
    <div className={styles.searchInput} data-hook="search-input">
      {this.props.searchInput}
    </div>
  );

  render() {
    const { isAuthenticated, showLogin, viewMode } = this.props;

    const isEditor = viewMode && viewMode === 'editor';

    // For app settings toggling
    if (isEditor && showLogin) {
      return this.renderLogin();
    }

    if (!isAuthenticated) {
      return this.renderLogin();
    } else {
      return this.renderMainWidget();
    }
  }
}

BreadcrumbsWidget.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool.isRequired,
  isLoginWidgetEnabled: PropTypes.bool,
  requestLogin: PropTypes.func,
  t: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  searchInput: PropTypes.node,
  currentUser: PropTypes.object,
  openModal: PropTypes.func,
  buttonClicked: PropTypes.func,
  category: PropTypes.object,
  params: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const getRouteCategory = () => {
    const routeParams = getRouteParams(state);
    if (routeParams && routeParams.categorySlug) {
      return getCategoryBySlug(state, routeParams.categorySlug) || {};
    }
    return {};
  };

  const categories = getAllCategories(state);
  const category = categories.length === 1 ? categories[0] : getRouteCategory();

  return {
    // showLogin: state.breadcrumbsWidget.showLogin, // @todo: s.dubinskas add reducer
    showLogin: false,
    viewMode: getViewMode(state),
    isLoginWidgetEnabled: getIsLoginWidgetEnabled(state, host.style),
    category,
    openModal: actions.openModal,
    buttonClicked: actions.buttonClicked,
    requestLogin: keepFocus(actions.requestLoginPromisified),
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.BASIC_PARAMS]),
  withAuth,
  withCardBackgroundColor,
  withTranslate,
  withPermissions,
  withFontClassName,
)(BreadcrumbsWidget);
