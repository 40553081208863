import 'react';
import PropTypes from 'prop-types';
import withTranslate from '../with-translate/with-translate';

const TYPE_MSG = 1;
const TYPE_PROP = 2;

/* Utility for interpolating translations with components.
Example: 
you have a key {"bar.title": "{click} here to go"}
to inter link into {click} use do this:
<Interpolate i18nKey="bar.title" click={<a href="">Clickity click</a>} />
*/
const Interpolate = ({ t, i18nKey, ...customProps }) => {
  const int = Object.entries(customProps);
  const txt = t(i18nKey);
  let result = [{ value: txt, type: TYPE_MSG }];

  for (const [placeholder, valueProp] of int) {
    result = result.reduce((r, { value, type }) => {
      if (type === TYPE_MSG) {
        const toReplace = value.split(`{${placeholder}}`);

        if (toReplace.length === 1) {
          r.push({ value, type });
        } else {
          toReplace.forEach((valueTxt, index) => {
            r.push({ value: valueTxt, type: TYPE_MSG });

            if (toReplace[index + 1] !== undefined) {
              r.push({ value: valueProp, type: TYPE_PROP });
            }
          });
        }
      } else {
        r.push({ value, type });
      }

      return r;
    }, []);
  }

  return result.map(({ value }) => value);
};

Interpolate.propTypes = {
  i18nKey: PropTypes.string,
  customProps: PropTypes.object,
};

export default withTranslate(Interpolate);
