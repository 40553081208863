import { keyBy } from 'lodash';
import { PAID_PLANS_APP_ID } from '../constants/applications';
import { getApplicationConfig } from '@wix/communities-forum-client-commons/dist/src/selectors/communities-context-selectors';
import { getMembersGroups } from '@wix/communities-forum-client-commons/dist/src/selectors/members-groups-selectors';

export const getPaidPlanById = (state, planId) => state.paidPlans.find(p => p.id === planId);
export const getAllPaidPlans = state => state.paidPlans || [];

export const hasPaidPlansForOwner = state => getAllPaidPlans(state).length;

export const hasPaidPlans = state => {
  if (!getApplicationConfig(state, PAID_PLANS_APP_ID).isInstalled) {
    return false;
  }
  return hasPaidPlansForOwner(state);
};

export const hasCategoryPaidPlansForOwner = (state, groups) => {
  groups = groups || [];
  const plans = getAllPaidPlans(state);
  const plansById = keyBy(plans, 'id');
  for (const groupId of groups) {
    if (plansById[groupId]) {
      return true;
    }
  }
  return false;
};

export const hasCategoryPaidPlans = (state, groups = []) => {
  if (!getApplicationConfig(state, PAID_PLANS_APP_ID).isInstalled) {
    return false;
  }
  return hasCategoryPaidPlansForOwner(state, groups);
};

export const getPaidPlanGroups = state => {
  const plans = getAllPaidPlans(state).map(p => p.id);
  const list = getMembersGroups(state).filter(group => plans.includes(group.id));
  const byId = keyBy(list, 'id');

  return {
    list,
    byId,
    count: list.length,
  };
};
