import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { MODAL_TYPE_FILTER_AND_SORT } from '../modals/filter-and-sort-modal/filter-and-sort-modal-type';
import { getCategoryPageSorting } from '../../selectors/sorting-selectors';
import FiltersBarMobile from './filters-bar-mobile';
import FilterAndSortButton from '../filter-and-sort-button';

const CategoryPageFiltersBar = ({ sort, openModal }) => (
  <FiltersBarMobile
    currentSort={sort}
    rightSideComponent={
      <FilterAndSortButton
        onClick={() => openModal(MODAL_TYPE_FILTER_AND_SORT, { shouldShowFilter: true })}
        isFilter
      />
    }
  />
);

CategoryPageFiltersBar.propTypes = {
  sort: PropTypes.string,
  openModal: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  sort: getCategoryPageSorting(state, host.style),
  openModal: actions.openModal,
});

export default connect(mapRuntimeToProps)(CategoryPageFiltersBar);
