export const getIsLoading = state => state.isLoading;

export const getIsEntityLoading = (state, entity, id = '') =>
  Boolean((getIsLoading(state)[entity] || {})[id]);

export const getIsCategoryLoading = createIsLoadingSelector('category');
export const getIsPostLoading = createIsLoadingSelector('post');
export const getIsPostsLoading = createIsLoadingSelector('posts');
export const getIsProfilePostsLoading = createIsLoadingSelector('profile-posts');
export const getIsProfileCommentsLoading = createIsLoadingSelector('profile-comments');
export const getIsProfileLikesLoading = createIsLoadingSelector('profile-likes');
export const getIsCommentsLoading = createIsLoadingSelector('comments');
export const getIsNotificationsLoading = createIsLoadingSelector('notifications');
export const getIsSearchLoading = createIsLoadingSelector('search');
export const getIsMembersLoading = createIsLoadingSelector('members');
export const getIsFollowersLoading = createIsLoadingSelector('followers');
export const getIsFollowingLoading = createIsLoadingSelector('following');
export const getIsUploadLoading = createIsLoadingSelector('upload');
export const getIsFooterPostsLoading = createIsLoadingSelector('footer-posts');
export const getIsSimilarPostsLoading = createIsLoadingSelector('similar-posts');
export const getIsExternalLinksLoading = createIsLoadingSelector('external-links');
export const getIsRCELoading = createIsLoadingSelector('rce');

function createIsLoadingSelector(entity) {
  return (state, id) => getIsEntityLoading(state, entity, id);
}
