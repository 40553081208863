import React from 'react';
import Header from '../../components/header';
import HeaderButtons from '../../components/header-buttons';
import HeaderTitle from '../../components/header-title';
import PropTypes from 'prop-types';

const CategoriesPageHeader = ({ params }) => (
  <Header left={<HeaderTitle />} right={<HeaderButtons params={params} />} />
);

CategoriesPageHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

export default CategoriesPageHeader;
