import { connect } from '../../common/components/runtime-context';
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_PINBOARD,
  LAYOUT_TYPE_COMPACT,
} from '@wix/communities-forum-client-commons/dist/src/constants/layout-types';
import { getLayoutType } from '../selectors/layout-selectors';

export default function withLayoutType(WrappedComponent) {
  const mapRuntimeToProps = (state, ownProps, actions, host) => {
    const layoutType = getLayoutType(state, host.style);
    return {
      isClassicLayout: layoutType === LAYOUT_TYPE_CLASSIC,
      isPinboardLayout: layoutType === LAYOUT_TYPE_PINBOARD,
      isCompactLayout: layoutType === LAYOUT_TYPE_COMPACT,
    };
  };

  return connect(mapRuntimeToProps)(WrappedComponent);
}
