import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getCommentsByPostId, getCommentsByPostIdAndPage } from '../../selectors/comment-selectors';
import { getEntityCount } from '../../selectors/pagination-selectors';
import { getEditedCommentId } from '../../selectors/edited-comment-id-selectors';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getPostBySlug } from '../../selectors/post-selectors';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import CommentList from '../../components/comment-list';
import withDeviceType from '../../hoc/with-device-type';
import styles from './post-comment-list.scss';

export class PostCommentList extends Component {
  componentWillUnmount = () => {
    this.props.setIsLoaded('comments', this.props.postId, false);
  };

  render = () => {
    const { allComments, currentPageComments, isCommentsLoading } = this.props;
    if (!allComments.length) {
      return null;
    }

    if (currentPageComments.length) {
      return this.renderCommentList(currentPageComments);
    }

    if (currentPageComments.length === 0 && isCommentsLoading) {
      return (
        <div className={styles.loader}>
          <Loader />
        </div>
      );
    }

    return null;
  };

  renderCommentList = comments => {
    const { incrementCommentLikeCount, editedCommentId, bestAnswerCommentId } = this.props;
    return (
      <CommentList
        comments={comments}
        onLikeClick={incrementCommentLikeCount}
        editedCommentId={editedCommentId}
        bestAnswerCommentId={bestAnswerCommentId}
      />
    );
  };
}

PostCommentList.propTypes = {
  editedCommentId: PropTypes.string,
  bestAnswerCommentId: PropTypes.string,
  allComments: PropTypes.array.isRequired,
  currentPageComments: PropTypes.array.isRequired,
  entityCount: PropTypes.number,
  postId: PropTypes.string,
  isMobile: PropTypes.bool,
  isCommentsLoading: PropTypes.bool,
  isDeepLinked: PropTypes.bool,
  setIsLoaded: PropTypes.func,
  incrementCommentLikeCount: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const post = getPostBySlug(state, ownProps.postSlug) || {};
  const page = ownProps.page;
  return {
    currentPageComments: getCommentsByPostIdAndPage(state, post._id, page),
    allComments: getCommentsByPostId(state, post._id),
    entityCount: getEntityCount(state, 'comments', post._id),
    editedCommentId: getEditedCommentId(state),
    bestAnswerCommentId: post.bestAnswerCommentId,
    postId: post._id,
    incrementCommentLikeCount: actions.incrementCommentLikeCount,
    setIsLoaded: actions.setIsLoaded,
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [
    REDUCERS.COMMENTS,
    REDUCERS.PAGINATION,
    REDUCERS.COMMENT_EDIT,
    REDUCERS.POSTS,
  ]),
  withDeviceType,
)(PostCommentList);
