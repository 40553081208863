let promptHandlers = [];

export const enableNavigationPrompt = cb => {
  promptHandlers.push(cb);

  return () => {
    promptHandlers = promptHandlers.filter(x => x !== cb);
  };
};

const buildPauseHandler = (navigate, pause) => handler => {
  let isPaused = false;

  const resume = () => {
    promptHandlers = promptHandlers.filter(x => x !== handler);
    if (isPaused) {
      navigate();
    }
    isPaused = false;
  };

  return () => {
    pause();
    isPaused = true;
    return { resume };
  };
};

const handleDocumentClick = event => {
  let element = event.target;
  while (element.tagName.toLowerCase() !== 'a') {
    element = element.parentElement;
    if (!element) {
      return;
    }
  }

  if (element.getAttribute('target') === '_blank') {
    return;
  }

  const pauseNavigation = () => {
    event.preventDefault();
    event.stopImmediatePropagation();
  };
  const navigate = () => {
    event.target.click();
  };
  const pauseHandler = buildPauseHandler(navigate, pauseNavigation);

  promptHandlers.forEach(handler => handler(pauseHandler(handler)));
};

export const initNavigationPrompt = () => {
  if (typeof window !== 'undefined') {
    window.document.addEventListener('click', handleDocumentClick, true);
  }
};

export const destroyNavigationPrompt = () => {
  if (typeof window !== 'undefined') {
    window.document.removeEventListener('click', handleDocumentClick);
  }
};
