import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import PostStats, { STAT_TYPES } from '../post-stats';
import styles from './post-page-stats.scss';

const ANIMATION_TIMEOUT = 1200;

const PostPageStats = ({
  viewCount,
  commentCount,
  replyCount,
  isAnimated,
  shouldShowCommentsFirst,
  postType,
  postId,
}) => {
  return (
    <CSSTransitionGroup
      transitionName={styles}
      transitionEnterTimeout={ANIMATION_TIMEOUT}
      transitionAppearTimeout={ANIMATION_TIMEOUT}
      transitionLeaveTimeout={0}
      transitionAppear={isAnimated}
      component="div"
    >
      <div key="stats">
        <div className={styles.container}>
          <PostStats
            postId={postId}
            viewCount={viewCount}
            commentCount={commentCount}
            replyCount={replyCount}
            postType={postType}
            customRenderOrder={
              shouldShowCommentsFirst && [STAT_TYPES.COMMENTS, STAT_TYPES.VIEWS, STAT_TYPES.REPLIES]
            }
          />
        </div>
      </div>
    </CSSTransitionGroup>
  );
};

PostPageStats.propTypes = {
  viewCount: PropTypes.number,
  commentCount: PropTypes.number,
  replyCount: PropTypes.number,
  isAnimated: PropTypes.bool,
  postType: PropTypes.string,
  postId: PropTypes.string,
  customRenderOrder: PropTypes.array,
  shouldShowCommentsFirst: PropTypes.bool,
};

PostPageStats.defaultProps = {
  shouldShowCommentsFirst: false,
};

export default PostPageStats;
