import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './external-link.scss';
import URI from 'urijs';

const addQueryParams = (url, query) => new URI(url).addQuery(query).toString();

const ExternalLink = ({
  to,
  baseUrl,
  children,
  applyButtonColor = true,
  className,
  queryParams,
  ...props
}) => {
  const url = queryParams ? addQueryParams(to, queryParams) : to;

  return (
    <a
      className={classNames(styles.root, applyButtonColor && 'button-color', className)}
      href={url}
      target="_blank"
      {...props}
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  baseUrl: PropTypes.string,
  className: PropTypes.string,
  queryParams: PropTypes.object,
};

export default ExternalLink;
