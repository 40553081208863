export const CREATE_POST = 'create-post';
export const CREATE_REPLY = 'create-reply';
export const UPDATE_POST = 'update-post';
export const LIKE_POST = 'like-post';
export const DELETE_POST = 'delete-post';
export const CREATE_COMMENT = 'create-comment';
export const UPDATE_COMMENT = 'update-comment';
export const LIKE_COMMENT = 'like-comment';
export const DELETE_COMMENT = 'delete-comment';
export const CREATE_CATEGORY = 'create-category';
export const UPDATE_CATEGORY = 'update-category';
export const DELETE_CATEGORY = 'delete-category';
export const FOLLOW_CATEGORY = 'follow-category';
export const FOLLOW_POST = 'follow-post';
export const UNFOLLOW_CATEGORY = 'unfollow-category';
export const SSR_SUCCESS = 'ssr-success';
export const PROVISION_SUCCESS = 'provision-success';
export const CREATE_NEW_POST = 'create-new-post';
export const CREATE_NEW_QUESTION = 'create-new-question';
export const UPVOTE_COMMENT = 'upvote-comment';
export const DOWNVOTE_COMMENT = 'downvote-comment';
