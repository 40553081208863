import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { VoteUpIcon, VoteUpIconMobile } from './vote-up-icon'; // TODO: use forDevice
import { VoteDownIcon, VoteDownIconMobile } from './vote-down-icon'; // TODO: use forDevice
import { getVoteCount } from '../../selectors/comment-selectors';
import { DOWNVOTE_COMMENT, UPVOTE_COMMENT } from '../../constants/interactions';
import withDeviceType from '../../hoc/with-device-type';
import ProtectedButton from '../../containers/protected-button';
import styles from './comment-vote.scss';
import withTranslate from '../../../common/components/with-translate/with-translate';

const voteType = {
  UP: 'up',
  DOWN: 'down',
};

const getVoteType = (isUpvoted, isDownvoted) => {
  if (isUpvoted) {
    return voteType.UP;
  }
  if (isDownvoted) {
    return voteType.DOWN;
  }
  return null;
};

class CommentVote extends Component {
  isVoted = type => this.props.voted !== null && type === this.props.voted;

  vote = type => () => {
    const { upvoteComment, downvoteComment, unvoteComment } = this.props;

    if (this.isVoted(type)) {
      return unvoteComment();
    }

    if (type === voteType.UP) {
      return upvoteComment();
    }
    downvoteComment();
  };

  getActionDetails = type => {
    const { postId, commentId } = this.props;
    const action = type === voteType.UP ? UPVOTE_COMMENT : DOWNVOTE_COMMENT;
    return { action, args: [postId, commentId] };
  };

  getVoteIcon = type => {
    const isUpvote = type === voteType.UP;
    if (this.props.isMobile) {
      return isUpvote ? VoteUpIconMobile : VoteDownIconMobile;
    } else {
      return isUpvote ? VoteUpIcon : VoteDownIcon;
    }
  };

  renderVoteIcon = type => {
    const { voted, isMobile } = this.props;
    const Icon = this.getVoteIcon(type);
    const handleVote = this.vote(type);
    const isVoted = voted === type;
    return (
      <ProtectedButton
        className={classNames(styles.voteIcon, {
          [styles.voteIconInactive]: !isVoted,
        })}
        onClick={handleVote}
        actionDetails={this.getActionDetails(type)}
        data-hook={type === voteType.UP ? 'comment__upvote-button' : 'comment__downvote-button'}
      >
        <Icon
          className={classNames(
            { 'button-hover-fill': !isMobile && !isVoted },
            isVoted ? 'button-fill' : 'forum-icon-fill',
          )}
        />
      </ProtectedButton>
    );
  };

  render() {
    const { voteCount, t } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {this.renderVoteIcon(voteType.UP)}
          <span
            className={classNames(styles.voteCount, 'forum-text-color', 'forum-content-small-font')}
            data-hook={'comment__vote-score'}
          >
            {t('number', { count: voteCount })}
          </span>
          {this.renderVoteIcon(voteType.DOWN)}
        </div>
      </div>
    );
  }
}

CommentVote.propTypes = {
  comment: PropTypes.object,
  postId: PropTypes.string,
  commentId: PropTypes.string,
  isMobile: PropTypes.string,
  upvoteComment: PropTypes.func,
  downvoteComment: PropTypes.func,
  unvoteComment: PropTypes.func,
  voteCount: PropTypes.number,
  voted: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const { postId, _id: commentId, isUpvoted, isDownvoted } = ownProps.comment;

  return {
    postId,
    commentId,
    voted: getVoteType(isUpvoted, isDownvoted),
    upvoteComment: () => actions.upvoteComment({ postId, commentId }),
    downvoteComment: () => actions.downvoteComment({ postId, commentId }),
    unvoteComment: () => actions.unvoteComment({ postId, commentId }),
    voteCount: getVoteCount(ownProps.comment),
  };
};

export default flowRight(connect(mapRuntimeToProps), withDeviceType, withTranslate)(CommentVote);
