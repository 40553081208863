const makePath = location =>
  (location.url || '')
    .replace(location.baseUrl, '')
    .split('/')
    .filter(part => part);

export const transformWixCodeLocation = async (wixCodeApi, sectionUrl = '', locationPath) => {
  const location = wixCodeApi.location;
  const hasCustomSegments =
    wixCodeApi.site &&
    wixCodeApi.site.getCustomizedUrlSegments &&
    !!(await wixCodeApi.site.getCustomizedUrlSegments(location.url));
  locationPath = locationPath || location.path;
  const lastPathPart = sectionUrl
    .split('/')
    .filter(part => part)
    .pop();
  const indexOfLastPart = locationPath.indexOf(lastPathPart);

  let path = [];
  if (hasCustomSegments) {
    path = makePath(location);
  } else if (indexOfLastPart !== -1) {
    path = locationPath.slice(indexOfLastPart + 1);
  }

  return {
    url: location.url,
    baseUrl: location.baseUrl,
    // path: location.path,
    sectionUrl,
    pathname: '/' + path.join('/'),
    protocol: location.protocol,
    query: location.query,
    hash: location.hash,
  };
};
