import { omit, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import URI from 'urijs';
import { connect } from '../../../common/components/runtime-context';
import { NAVIGATE_WITHIN_FORUM } from '../../../common/actions/navigate-within-forum';
import getInnerUrl from '../../services/get-inner-url';
import getOuterUrl from '../../services/get-outer-url';
import { isSeo } from '../../../common/store/basic-params/basic-params-selectors';
import { getSectionUrl } from '../../../common/store/location/location-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { EXPERIMENT_URL_CUSTOMIZATION } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import withExperiment from '../../hoc/with-experiment';
import withCustomUrlBuilder from '../../hoc/with-custom-url-builder';

function getLocation(path, sectionUrl) {
  return new URI(getOuterUrl(path, sectionUrl));
}

const isModifiedEvent = event =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

class InternalLink extends React.PureComponent {
  state = {
    waitingForResolver: false,
  };

  render() {
    const {
      navigateWithinForum,
      to,
      onClick,
      children,
      renderInSeo,
      fullUrl,
      sectionUrl,
      isSeo,
      asClickHandler,
      dynamicLocationResolver,
      routeParams,
      isUrlCustomizationEnabled,
      buildCustomizedUrl,
      ...props
    } = this.props;

    const linkProps = omit(props, 'dispatch', 'tReady', 'i18n', 'user', 'lng');

    linkProps.style = linkProps.style || {};
    if (asClickHandler) {
      linkProps.style.cursor = 'pointer';
    }
    if (this.state.waitingForResolver) {
      linkProps.style.cursor = 'wait';
    }

    const goTo = to => navigateWithinForum(fullUrl ? getInnerUrl(to, sectionUrl) : to);

    const handleClick = event => {
      if (onClick) {
        onClick(event);
      }
      if (event.defaultPrevented) {
        return;
      }

      if (!isModifiedEvent(event)) {
        event.preventDefault();
        event.stopPropagation();

        if (dynamicLocationResolver && this.dynamicResolverResult === undefined) {
          this.setState({ waitingForResolver: true });
          this.dynamicResolverResult = dynamicLocationResolver();
        }

        if (this.dynamicResolverResult) {
          this.dynamicResolverResult.then(to => {
            this.setState({ waitingForResolver: false });
            goTo(to);
          });
        } else {
          goTo(to);
        }
      }
    };

    let location;
    const customUrl = isUrlCustomizationEnabled && buildCustomizedUrl(to);
    if (fullUrl) {
      location = new URI(to);
    } else if (typeof to === 'object') {
      console.error('InternalLink "to" should not be an object!', to);
      location = new URI(getOuterUrl(to.pathname, sectionUrl));
      location.search(to.search);
      location.hash(to.hash);
    } else if (customUrl) {
      location = customUrl;
    } else {
      location = getLocation(to, sectionUrl);
    }

    if (isSeo && !renderInSeo) {
      return <div {...linkProps}>{children}</div>;
    }
    if (asClickHandler) {
      return (
        <div onClick={handleClick} {...linkProps}>
          {children}
        </div>
      );
    }
    return (
      <a href={location.toString()} onClick={handleClick} {...linkProps}>
        {children}
      </a>
    );
  }
}

InternalLink.propTypes = {
  navigateWithinForum: PropTypes.func,
  to: PropTypes.string,
  onClick: PropTypes.func,
  dynamicLocationResolver: PropTypes.func,
  children: PropTypes.node,
  renderInSeo: PropTypes.bool,
  fullUrl: PropTypes.bool,
  sectionUrl: PropTypes.string,
  isSeo: PropTypes.bool,
  asClickHandler: PropTypes.bool,
  biLocation: PropTypes.string,
  buildCustomizedUrl: PropTypes.func.isRequired,
  isUrlCustomizationEnabled: PropTypes.bool,
};

InternalLink.defaultProps = {
  renderInSeo: true,
};

const mapRuntimeToProps = (state, { biLocation, to }, actions) => ({
  sectionUrl: getSectionUrl(state),
  isSeo: isSeo(state),
  navigateWithinForum: (...args) => {
    biLocation && actions.setBiLocation(biLocation, NAVIGATE_WITHIN_FORUM);
    actions.navigateWithinForum(...args);
  },
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.BASIC_PARAMS]),
  withExperiment({ isUrlCustomizationEnabled: EXPERIMENT_URL_CUSTOMIZATION }),
  withCustomUrlBuilder,
)(InternalLink);
